import React from "react";
import { Div, Row, Col, H6, Text, Icons, Image } from "qdm-component-library";
import clinicName from "../../../../assets/img/clinic_profile/clinicName.svg";
import description from "../../../../assets/img/clinic_profile/description.svg";
import facilityName from "../../../../assets/img/clinic_profile/facilityName.svg";
import facilityType from "../../../../assets/img/clinic_profile/facilityType.svg";
import speciality from "../../../../assets/img/clinic_profile/speciality.svg";
import timings from "../../../../assets/img/clinic_profile/timing.svg";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { convertTime } from "../../../../utils";
import moment from "moment"
import withRouter from "../../../../hoc/withRouter";
import { Card } from "@material-ui/core";

const dayObj = {
  "mon": "Monday",
  "tue": "Tuesday",
  "wed": "Wednesday",
  "thu": "Thursday",
  "fri": "Friday",
  "sat": "Saturday",
  "sun": "Sunday"
};
const shortDays = {
  "Monday": "Mon",
  "Tuesday": "Tue",
  "Wednesday": "Wed",
  "Thursday": "Thu",
  "Friday": "Fri",
  "Saturday": "Sat",
  "Sunday": "Sun",
};
let listOfDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
function ClinicProfile(props) {
  const [state, setState] = React.useState({
    imgUrl: props?.imgUrl || "https://via.placeholder.com/179x158",
    facilityName: props?.facilityName ?? "---",
    facilityType: props?.facilityType ?? "---",
    clinicName: props?.clinicName ?? "---",
    speciality: props?.speciality ?? "---",
    description: props?.description ?? "---",
    isAllDay: false,
    timings: [
      // {
      //   day: props?.timings[0]?.day ?? "Mon",
      //   timings: [
      //     props?.timings[0]?.daysOfWeek[0].openingtime,
      //     props?.timings[0]?.daysOfWeek[0].closingtime,
      //   ] ?? ["null", "null"],
      // },
      // {
      //   day: props?.timings[1]?.day ?? "Tues",
      //   timings:[
      //     props?.timings[1]?.daysOfWeek[1].openingtime,
      //     props?.timings[1]?.daysOfWeek[1].closingtime,
      //   ] ?? ["null", "null"]
      // },
      // {
      //   day: props?.timings[2]?.day ?? "Wednes",
      //   timings:[
      //     props?.timings[2]?.daysOfWeek[2].openingtime,
      //     props?.timings[2]?.daysOfWeek[2].closingtime,
      //   ] ?? ["null", "null"]
      // },
      // {
      //   day: props?.timings[3]?.day ?? "Thurs",
      //   timings:[
      //     props?.timings[3]?.daysOfWeek[3].openingtime,
      //     props?.timings[3]?.daysOfWeek[3].closingtime,
      //   ] ?? ["null", "null"]
      // },
      // {
      //   day: props?.timings[4]?.day ?? "Fri",
      //   timings:[
      //     props?.timings[4]?.daysOfWeek[4].openingtime,
      //     props?.timings[4]?.daysOfWeek[4].closingtime,
      //   ] ?? ["null", "null"]
      // },
      // {
      //   day: props?.timings[5]?.day ?? "Sat",
      //   timings:[
      //     props?.timings[5]?.daysOfWeek[5].openingtime,
      //     props?.timings[5]?.daysOfWeek[5].closingtime,
      //   ] ?? ["null", "null"]
      // },
      // {
      //   day: props?.timings[6]?.day ?? "Sun",
      //   timings:[
      //     props?.timings[6]?.daysOfWeek[6].openingtime,
      //     props?.timings[6]?.daysOfWeek[6].closingtime,
      //   ] ?? ["null", "null"]
      // },
    ],
  });
  const [loading, setLoading] = React.useState(false);
  const iconsSrc = {
    facilityName,
    facilityType,
    clinicName,
    speciality,
    description,
    timings,
  };

  React.useEffect(() => {
    setLoading(true);
    let isAllDay = false;
    let timings = props?.workigDays?.map(w => {
      if (w.allday) {
        isAllDay = true;
      }
      return {
        day: dayObj[w.day?.toLowerCase?.()],
        timings: w?.daysOfWeek?.map(dw => `${convertTime(dw?.openingtime, "unix", "HH:mm")} - ${convertTime(dw?.closingtime, "unix", "HH:mm")}`)
      }
    });
    let over = props?.overRide?.map((time, i) => {
      let start = moment(props.currentDate, "MM-DD-YYYY").startOf('week')
      let end = moment(props.currentDate, "MM-DD-YYYY").endOf('week')
      if (time.overridetype === "Single") {
        let day = moment.unix(time.ovrfromdate).format("dddd");
        let diff = moment(moment.unix(time.ovrfromdate)).diff(start, 'day')
        if (diff >= 0 && diff < 7) {
          timings = timings.filter(val => val.day !== day)
          timings.push({
            day: day,
            timings: time.operationTime[0].ovrdays_of_week?.map(dw => `${convertTime(dw.ovrfromtime, "unix", "HH:mm")} - ${convertTime(dw?.ovrtotime, "unix", "HH:mm")}`)
            // timings: [, convertTime(time.operationTime[0].ovrdays_of_week[0].ovrtotime)]
          })
        }
      } else {
        let startday = moment.unix(time.ovrfromdate)
        let endday = moment.unix(time.ovrtodate)
        let startdiff = moment(startday).diff(moment(start), 'day')
        let enddiff = moment(endday).diff(moment(start), 'day')
        if (startdiff >= 0 && startdiff < 7) {
          if (enddiff >= 0 && enddiff < 7) {
            listOfDays.slice(startdiff, enddiff + 1).map(val => {
              let daydetails = time.operationTime.filter(value => value.ovrday === shortDays[val])[0]
              timings = timings.filter(value => value.day !== val)
              timings.push({
                day: val,
                timings: daydetails.ovrdays_of_week?.map(dw => `${convertTime(dw.ovrfromtime, "unix", "HH:mm")} - ${convertTime(dw?.ovrtotime, "unix", "HH:mm")}`)
              })
            })
          } else {
            listOfDays.slice(startdiff, 7).map(val => {
              let daydetails = time.operationTime.filter(value => value.ovrday === shortDays[val])[0]
              timings = timings.filter(value => value.day !== val)
              timings.push({
                day: val,
                timings: daydetails.ovrdays_of_week?.map(dw => `${convertTime(dw.ovrfromtime, "unix", "HH:mm")} - ${convertTime(dw?.ovrtotime, "unix", "HH:mm")}`)
              })
            })
          }
        } else if (enddiff >= 0 && enddiff < 7) {
          listOfDays.slice(0, enddiff + 1).map(val => {
            let daydetails = time.operationTime.filter(value => value.ovrday === shortDays[val])[0]
            timings = timings.filter(value => value.day !== val)
            timings.push({
              day: val,
              timings: daydetails.ovrdays_of_week?.map(dw => `${convertTime(dw.ovrfromtime, "unix", "HH:mm")} - ${convertTime(dw?.ovrtotime, "unix", "HH:mm")}`)
            })
          })

        }
      }
    })
    // if(props?.overRide?.length > 0){
    let arr = [];
    listOfDays?.map(val => {
      let d = timings?.filter(value => value?.day === val)[0]
      arr.push(d)
    })
    setState({
      ...state,
      timings:arr,
      isAllDay,
    })
    // }
    setTimeout(() => {
      setLoading(false);
    }, 0);
  }, []);

  const { parent_id } = props;
  const styles = {
    colPadding: {
      padding: 7,
    },
    wrapper: {
      padding: "0px 20px 20px 20px",
      overflow: "auto",
    },
    mainTitle: {
      fontSize: 14,
      color: "#101010",
    },
    title: {
      fontSize: 12,
      color: "#6f6f6f",
      margin: 0,
      marginBottom: 5,
    },
    h6: {
      fontSize: 12,
      color: "#36A2EB",
      margin: 0,
      textTransform: "uppercase",
    },
    h5: {
      fontSize: 12,
      color: "#6F6F6F",
      display: "block",
      margin: 0,
      marginBottom: 10,
    },
    blackSpan: {
      color: "#101010",
      fontSize: 10,
    },
    span: {
      color: "#101010",
      fontSize: 14,
    },
    content: {
      marginBottom: 20,
    },
    specialityWrap: {
      border: "0.5px solid #E0E0E0",
      borderRadius: 8,
      padding: 5,
      display: "flex",
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    icons: {
      marginRight: 10,
      color: "#36A2EB",
    },
    docImage: {
      height: "100%",
      minHeight: "260px",
      borderRadius: "0px 0px 8px 8px",
      boxShadow: "0px 1px 10px #00000029",
      overflow: "hidden",
    },
    underline: {
      width: "100%",
      height: "2px",
      // background: '#E0E0E0',
      // marginTop: '7px',
      borderBottom: "1.7px dashed #E0E0E0",
    },
  };

  return (
    <div id={`${parent_id}_clinic_profile_parent_div`} style={styles.wrapper}>
      {loading ? null : (
        <>
        <Card>
          <Row id={`${parent_id}_clinic_profile_parent_row`}>
            <Col
              id={`${parent_id}_clinic_profile_img_col`}
              xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3}
              inLineStyles={{ ...styles.colPadding, paddingTop: 20 }}
            >
              <Image
                id={`${parent_id}_clinic_profile_image`}
                src={state?.imgUrl}
                style={{
                  width: "100%",
                  maxHeight: "200px",
                  objectFit: "cover",
                }}
              // inLineStyles={{
              //   height: "158px",
              //   width: "179px",
              // }}
              />
            </Col>
            <Col
              id={`${parent_id}_clinic_profile_info_col`}
              xs={12}
              sm={12}
              md={6}
              lg={9}
              xl={9}
              inLineStyles={{ ...styles.colPadding, paddingTop: 20 }}
            >
              <Row id={`${parent_id}_clinic_info_heading_row`}>
                <Col
                  id={`${parent_id}_clinic_info_heading_col`}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  inLineStyles={{
                    ...styles.colPadding,
                    paddingTop: 0,
                    paddingBottom: 10,
                  }}
                >
                  <Div
                    id={`${parent_id}_clinic_info_heading_div`}
                    style={{ display: "flex" }}
                  >
                    <H6
                      id={`${parent_id}_clinic_info_heading_h1`}
                      className="pc_semibold"
                      style={styles.mainTitle}
                      inLineStyles={{
                        marginLeft: 0,
                        marginRight: 7,
                        minWidth: "85px",
                      }}
                    >
                      CLINIC INFO
                    </H6>
                    <Div
                      id={`${parent_id}_clinic_info_heading_underline`}
                      style={styles.underline}
                      inLineStyles={{ marginTop: "10px" }}
                    >
                      &nbsp;
                    </Div>
                  </Div>
                </Col>
              </Row>
              <Row id={`${parent_id}_clinic_profile_info_row`}>
                <Col
                  id={`${parent_id}_clinic_profile_name_col`}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={8}
                  xl={8}
                  inLineStyles={{ ...styles.colPadding, paddingTop: 0 }}
                >
                  <Div
                    id={`${parent_id}_clinic_profile_name_div`}
                    style={styles.content}
                  >
                    <H6
                      id={`${parent_id}_clinic_profile_name_h6`}
                      className="pc_semibold"
                      style={styles.title}
                    >
                      <Image
                        inLineStyles={{
                          height: "12px",
                          width: "12px",
                        }}
                        id={`${parent_id}_clinic_profile_fcaility_name_img`}
                        src={iconsSrc.facilityName}
                      />
                      &nbsp; Facility Name
                    </H6>
                    <H6
                      id={`${parent_id}_clinic_profile_name_val_h6`}
                      className="pc_semibold"
                      style={styles.span}
                    >
                      {state?.facilityName}
                    </H6>
                  </Div>
                </Col>
                <Col
                  id={`${parent_id}_clinic_facility_type_col`}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  inLineStyles={{ ...styles.colPadding, paddingTop: 0 }}
                >
                  <Div
                    id={`${parent_id}_clinic_facility_type_div`}
                    style={styles.content}
                  >
                    <H6
                      id={`${parent_id}_clinic_facility_type_h6`}
                      className="pc_semibold"
                      style={styles.title}
                    >
                      <Image
                        inLineStyles={{
                          height: "12px",
                          width: "12px",
                        }}
                        id={`${parent_id}_clinic_profile_fcaility_type_img`}
                        src={iconsSrc.facilityType}
                      />
                      &nbsp; Facility Type
                    </H6>
                    <H6
                      id={`${parent_id}_clinic_facility_type_val_h6`}
                      className="pc_semibold"
                      style={styles.span}
                    >
                      {state?.facilityType}
                    </H6>
                  </Div>
                </Col>
              </Row>
              <Row id={`${parent_id}_clinic_info_row`}>
                <Col
                  id={`${parent_id}_clinic_name_info_col`}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={8}
                  xl={8}
                  inLineStyles={{ ...styles.colPadding, paddingTop: 0 }}
                >
                  <Div
                    id={`${parent_id}_clinic_name_content_div`}
                    style={styles.content}
                  >
                    <H6
                      id={`${parent_id}_clinic_name_h6`}
                      className="pc_semibold"
                      style={styles.title}
                    >
                      <Image
                        inLineStyles={{
                          height: "12px",
                          width: "12px",
                        }}
                        id={`${parent_id}_clinic_profile_clinic_name_img`}
                        src={iconsSrc.clinicName}
                      />
                      &nbsp; Clinic Name
                    </H6>
                    <H6
                      id={`${parent_id}_clinic_name_val_h6`}
                      className="pc_semibold"
                      style={styles.span}
                    >
                      {state?.clinicName}
                    </H6>
                  </Div>
                </Col>
                <Col
                  id={`${parent_id}_clinic_speciality_col`}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={4}
                  inLineStyles={{ ...styles.colPadding, paddingTop: 0 }}
                >
                  <Div
                    id={`${parent_id}_clinic_speciality_content_div`}
                    style={styles.content}
                  >
                    <H6
                      id={`${parent_id}_clinic_speciality_h6`}
                      className="pc_semibold"
                      style={styles.title}
                    >
                      <Image
                        inLineStyles={{
                          height: "12px",
                          width: "12px",
                        }}
                        id={`${parent_id}_clinic_profile_speciality_img`}
                        src={iconsSrc.speciality}
                      />
                      &nbsp; Specialty
                    </H6>
                    <H6
                      id={`${parent_id}_clinic_speciality_val_h6`}
                      className="pc_semibold"
                      style={styles.span}
                    >
                      {state?.speciality}
                    </H6>
                  </Div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row id={`${parent_id}_clinic_profile_description_row`}>
            <Col
              id={`${parent_id}_clinic_profile_description_col`}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              inLineStyles={{ ...styles.colPadding, paddingTop: 0 }}
            >
              <Div
                id={`${parent_id}_clinic_profile_description_div`}
                style={styles.content}
              >
                <H6
                  id={`${parent_id}_clinic_profile_description_h6`}
                  className="pc_semibold"
                  style={styles.title}
                >
                  <Image
                    inLineStyles={{
                      height: "12px",
                      width: "12px",
                    }}
                    id={`${parent_id}_clinic_profile_description_name_img`}
                    src={iconsSrc.description}
                  />
                  &nbsp; Description
                </H6>
                <H6
                  id={`${parent_id}_clinic_profile_description_val_h6`}
                  className="pc_semibold"
                  style={styles.span}
                >
                  {state?.description}
                </H6>
              </Div>
            </Col>
          </Row>
          <Row id={`${parent_id}_clinic_timing_row`}>
            <Col
              id={`${parent_id}_clinic_timing_heading_col`}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              inLineStyles={{
                ...styles.colPadding,
                paddingTop: 0,
                paddingBottom: 10,
              }}
            >
              <Div
                id={`${parent_id}_clinic_timing_heading_div`}
                style={{ display: "flex" }}
              >
                <H6
                  id={`${parent_id}_clinic_timing_heading_h1`}
                  className="pc_semibold"
                  style={styles.mainTitle}
                  inLineStyles={{
                    marginLeft: 0,
                    marginRight: 7,
                  }}
                >
                  TIMINGS
                </H6>
                <Div
                  id={`${parent_id}_clinic_timing_underline`}
                  style={styles.underline}
                  inLineStyles={{ marginTop: "10px" }}
                >
                  &nbsp;
                </Div>
              </Div>
            </Col>
            <Col
              id={`${parent_id}_clinic_timing_sub_col`}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              inLineStyles={{
                ...styles.colPadding,
                paddingTop: 0,
                paddingBottom: 10,
              }}
            >
              <Row id={`${parent_id}_clinic_timing_sub_row`}>
                <Col
                  id={`${parent_id}_clinic_timing_icon_col`}
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  inLineStyles={{
                    ...styles.colPadding,
                    paddingTop: 0,
                    paddingBottom: 10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Div
                    style={{
                      background: "#10101008",
                      height: "40px",
                      width: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                    }}
                  >
                    <Image
                      id={`${parent_id}_clinic_profile_timing_img`}
                      src={iconsSrc.timings}
                      inLineStyles={{
                        height: "17px",
                        width: "17px",
                        margin: "auto",
                      }}
                    />
                  </Div>
                </Col>
                <Col
                  id={`${parent_id}_clinic_timing_val_col`}
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11}
                  xl={11}
                  inLineStyles={{
                    ...styles.colPadding,
                    paddingTop: 0,
                    paddingBottom: 10,
                  }}
                >
                  <Row>
                    {
                      state?.isAllDay
                        ? (
                          <>
                            <Col
                              id={`${parent_id}_clinic_timing_day_col`}
                              xs={12}
                              sm={6}
                              md={4}
                              lg={4}
                              xl={4}
                              inLineStyles={{
                                ...styles.colPadding,
                                paddingTop: 0,
                                marginTop: 5,
                                paddingBottom: 10,
                              }}
                            >
                              <H6
                                id={`${parent_id}_clinic_timing_day_text`}
                                className="pc_semibold"
                                style={styles.title}
                              >
                                24 x 7
                              </H6>
                              <H6
                                id={`${parent_id}_clinic_timing_timings_text`}
                                className="pc_semibold"
                                style={styles.span}
                              >
                                Available
                              </H6>
                            </Col>
                          </>
                        )
                        : (
                          state?.timings || []).map((time, i) => {
                            const condition = (i + 1) % 3 !== 0;
                            return (
                              <Col
                                id={`${parent_id}_clinic_timing_day_col`}
                                xs={12}
                                sm={6}
                                md={4}
                                lg={4}
                                xl={4}
                                inLineStyles={{
                                  ...styles.colPadding,
                                  paddingTop: 0,
                                  marginTop: 5,
                                  paddingBottom: 10,
                                  borderRight:
                                    condition && state?.timings.length !== i + 1
                                      ? "0.7px solid #E0E0E0"
                                      : "unset",
                                }}
                              >
                                <H6
                                  id={`${parent_id}_clinic_timing_day_text`}
                                  className="pc_semibold"
                                  style={styles.title}
                                >
                                  {time?.day}
                                </H6>
                                <H6
                                  id={`${parent_id}_clinic_timing_timings_text`}
                                  className="pc_semibold"
                                  style={styles.span}
                                >
                                  {time?.timings?.join("; ")}
                                </H6>
                              </Col>
                            );
                          }
                          )
                    }
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          </Card>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  clinicInfo: state?.clinicInfoSlice,
});

export default connect(mapStateToProps, actions)(withRouter(ClinicProfile));
