import React, { useState, useEffect, Suspense } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Button,
  TextField,
  Popover,
} from "@mui/material";
import { Col, Div, Row, TextInput } from "qdm-component-library"; // import {
import SearchIcon from "@mui/icons-material/Search";
import {
  AlertProps,
  AxiosCall,
  getDateAndTime,
  urls,
  utcTOLocal,
  __tenantid__,
  personRequests,
  getFacilityId,
  fetchData,
  makeName,
  getImgUrl,
  getGlobalValueFrom_LclStrg_And_QryStrg,
} from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/actions";
// import AppHeader from "../../components/layout/appHeader";
import PatientInfoCard from "./components/patientInfoCard";
import PatientCareTypeCard from "./components/patientCareTypeCard";
import cardIcon from "../../assets/img/svg/Group 90506.svg";
import { useStyles } from "./styles";
import { UIColor } from "../../themes/theme";
import ViewBilling from "./components/viewBilling";
import BillingSettlement from "./components/billingSettlement";
import { displayTable } from "./components/constructJson";
import { useNavigate, useLocation } from "react-router-dom";
import withAllContexts from "../../hoc/withAllContexts";
import withAppBar from "../../hoc/withAppBar";
import { AppRoutes } from "../../router/routes";
import dolarIcon from "../../assets/img/svg/Group 90507.svg";
import axios from "axios";
import jwtDecode from "jwt-decode";
import SplitCashPopup from "./components/splitCashPopup";
import AlertsRestriction from "../patientDetail/alertsRestriction";
import numberToWords from "number-to-words";
import EncounterListCard from "./components/encounterListCard";
import moment from "moment";
import { Dialog } from "@material-ui/core" //"@mui/material";
import FinanceCRUD from "../financeRegistration/components/modelFin";

const steps = ["Patient Order Details", "Generate Bill & Capture Settlement"];
const CashierBillingDetails = (props) => {
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const [loading, setLoading] = useState(false);
  const [insurancePayer, setInsurancePayer] = useState([]);
  const [outPlusWallte, setOutPlusWallte] = useState({});
  const [openSplitPayer, setOpenSplitPayer] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [payerSplitData, setPayerSplitData] = useState([]);
  const [payerSplitIndex, setPayerSplitIndex] = useState(null);
  const [alertData, setAlertData] = useState({
    open: false,
    data: null,
  });
  const [encounterDataList, setEncounterDataList] = useState([]);
  const [selectencounterDataList, setSelectEncounterDataList] = useState([]);
  const [Isdisableright, setIsdisableright] = useState(false);
  const [Isdisableleft, setIsdisableleft] = useState(false);
  const [isFin, setIsFin] = useState(false);
  const [encPageCount, setencPageCount] = useState(0);
  const [count, setcount] = useState(1);

  const closeAlertModal = (data) => {
    setAlertData({
      open: false,
      data: null,
    });

    if (data.type === 3) {
      if (data.action == "A") {
        handleAddNewOrder(data.val, true);
      } else if (data.action == "D") {
        deleteSelectedMultiData(data.val);
      }
    }
    // else if (data.type) {

    // }
    // else {
    //   setAddNewSwitch(false);
    // }
  };

  const handleOpenPopup = (event, row, i, ismultiPayer) => {
    setPayerSplitData(row);
    setPayerSplitIndex(i);
    setOpenSplitPayer(!openSplitPayer && ismultiPayer);
    setPopoverAnchorEl(event.currentTarget);
  };

  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    inputStyle: {
      padding: 5,
      marginBottom: 10,
      fontSize: "12px",
    },
  };

  const getInsuranceName = async (encounterId) => {
    const insurancePayer = await dispatch(actions.PAYER_DROPDOWN(encounterId));
    setInsurancePayer(insurancePayer?.payload);
    setPayer({
      _id: insurancePayer?.payload?.data?.[0]?._id,
      value: insurancePayer?.payload?.data?.[0]?.longdesc,
    });
  };
  const getBillNo = async () => {
    if (billNo?.billNum === null) {
      const billNom = await dispatch(actions.BILL_NO());

      setBillNo({
        ...billNo,

        billNum: billNom?.payload?.data?.Result?.billno ?? null,

        date: encounterDate, //getDateAndTime(new Date(new Date()) / 1000),
      });
    }
  };
  const getFinanceData = async (encounterid) => {
    //const patientId = LoadpatientData?.state?.patientId;

    const fin_data = await dispatch(
      actions.GET_ENCOUNTER_FINANCIAL({ encounterid })
    );

    if (fin_data?.payload?.data) {
      const fin_DATA = fin_data?.payload?.data?.map((v) => {
        const setdata = v?.payertype;

        return {
          pic: { dolarIcon },

          name: null,

          care_type: v?.payertype?.longdesc,

          //title_name: setdata?.receivertype + ", " + setdata?.policyid,

          title_name: v?.payer?.longdesc,
          priority: v?.priority,
          policyexpiry: v?.policyexpiry
            ? utcTOLocal(v?.policyexpiry, "DD-MM-YYYY")
            : "",
          policy: v?.policyid?.[0]?.plan?.longdesc,
          // planid:v?.policyid?.[0]?._id,
          planid: v?.policyid?.[0]?.plan?._id,
          payer: v?.payer?._id,
          encounteraccountid:v?.encounteraccountid,
        };
      });
      // const findata = fin_DATA?.reverse();
      setSelectOption(fin_DATA[0]);
      setFinData(fin_DATA);

      // this.setState({

      //   selectOption: fin_DATA[0],

      //   fin_data: fin_DATA,

      // });
    }
  };
  async function getOutstandingandWallet() {
    const Outstanding = await dispatch(
      actions.GET_OUTSTANDING_DATA(location?.state?.patientId)
    );

    setOutPlusWallte(Outstanding?.payload?.data?.[0]);
  }

  useEffect(() => {
    getTableData();
    loadDefaultValues();

    // getInsuranceName();
    getOutstandingandWallet();
  }, []);

  const location = useLocation();

  const LoadpatientData = location;

  // const encounter_id = LoadpatientData?.state?.encounter[counter]?._id;

  const encounterData = null;

  const classes = useStyles();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [encounterNo, setEncounterNo] = useState("");

  const [activeStep, setActiveStep] = useState(0);

  const [addNewSwitch, setAddNewSwitch] = useState(false);

  const [displayOrders, setDisplayOrders] = useState([]);

  const [unBilledCheckBox, setUnBilledCheckBox] = useState(false);

  const [selectedOrders, setSelectedOrders] = useState([]);

  const [allChecked, setAllChecked] = useState(false);

  const [newAddedOrder, setNewlyAdded] = useState([]);

  const [counter, setCounter] = useState(0);

  const [codeSearch, setCodeSearch] = useState("");

  const [standardTableorders, setStandardTableorders] = useState([]);

  const [billNo, setBillNo] = useState({
    billNum: null,

    date: "",
  });
  console.log(billNo, "billn");
  const [calculationResultData, setCalculationResultData] = useState({
    calculationResult: [],
  });

  const [sharePercent, setSharePercent] = useState({
    discountAmnt: 0,

    patientShare: 100,
  });

  const [payer, setPayer] = useState("");

  const [paymentDetails, setPaymentDetails] = useState({
    paymentType: "",

    settleNow: false,

    settleNowValue: "",

    balanceDue: 0.0,

    docRefNo: "",

    docRefDate: 0,

    remarks: "",

    card: "",

    expiry: "",
  });

  const [receipt, setReceipt] = useState({
    receiptNo: null,

    date: "",
  });

  const [roundOffAmnt, setRoundOffAmnt] = useState({
    patientShareFinal: 0.0,

    roundOff: 0.0,
  });

  const [selectOption, setSelectOption] = useState([]);

  const [finData, setFinData] = useState([]);

  const [allMasters, setAllMasters] = useState([]);

  const [chargeDropDwn, setChargeDropDwn] = useState([]);

  const [uomDropDwn, setUomDropDwn] = useState([]);
  const [encounterDate, setEncounterDate] = useState("");

  // Handle Payment Details

  const handlePayementDetails = async (e, type) => {
    if (type === "settleNowValue") {
      const balance = calculationResultData?.patientShare - e;

      setPaymentDetails({
        ...paymentDetails,

        [type]: e,

        balanceDue: balance,

        settleNowValue: calculationResultData?.patientShare,
      });
    } else if (type === "docRefDate" || type === "expiry") {
      if (type === "expiry") {
        const mon = e.getMonth();

        const year = e.getFullYear();

        const expiryDate = new Date(
          new Date(year, mon + 1, 0).setHours(23, 59, 59, 999)
        );

        const date = new Date(expiryDate) / 1000;

        setPaymentDetails({ ...paymentDetails, [type]: Math.floor(date) });
      } else {
        const date = new Date(e) / 1000;

        setPaymentDetails({ ...paymentDetails, [type]: date });
      }
    } else {
      setPaymentDetails({ ...paymentDetails, [type]: e });
    }

    if (type === "settleNow" && e === true && receipt?.receiptNo === null) {
      const chequeNo = await dispatch(actions.RECIEPT_NO());

      setReceipt({
        ...receipt,

        receiptNo: chequeNo?.payload?.data?.Result?.receiptno,

        date: getDateAndTime(new Date(new Date()) / 1000),
      });
    }
  };

  const onIncrement = async () => {
    const currentCount = counter;

    if (LoadpatientData?.state?.encounter.length - 1 > currentCount) {
      const setCount = currentCount + 1;

      const patientId = LoadpatientData?.state?.patientId;

      const encounterId =
        LoadpatientData?.state?.encounter[setCount]?._id ?? "";

      setCounter(setCount);

      const data = await dispatch(
        actions.GET_TABLE_DATA({ patientId, encounterId })
      );

      const tableData = displayTable(data?.payload?.data ?? []);

      setStandardTableorders(tableData);

      setDisplayOrders(tableData);
    }
  };

  const onDecrement = async () => {
    const currentCount = counter;

    if (currentCount > 0) {
      const setCount = currentCount - 1;

      const patientId = LoadpatientData?.state?.patientId;

      const encounterId =
        LoadpatientData?.state?.encounter[setCount]?._id ?? "";

      setCounter(setCount);

      const data = await dispatch(
        actions.GET_TABLE_DATA({ patientId, encounterId })
      );

      const tableData = displayTable(data?.payload?.data ?? []);

      setStandardTableorders(tableData);

      setDisplayOrders(tableData);
    }
  };

  React.useEffect(() => {
    // const newSelectedOrders = displayOrders.filter(
    //   (val, i) => val?.checked === true
    // );
    // const newUnSelectedOrders = displayOrders.filter(
    //   (val, i) => val?.checked === false
    // );
    // const selecteddet = JSON.parse(JSON.stringify(selectedOrders));
    // const newDisData =  newSelectedOrders.filter(x => !selecteddet.find(s => s.chargecodeid === x.chargecodeid && s.id === x.id));
    // const newunsDisData =  selecteddet.filter(x => newUnSelectedOrders.find(s => s.chargecodeid === x.chargecodeid && s.id === x.id));
    // newunsDisData.forEach(d => {
    //   const i = selecteddet?.indexOf(d);
    //   selecteddet.splice(i, 1);
    // });
    // newDisData.map((val, i) => selecteddet.push(val));
    // if (newAddedOrder.length > 0) {
    //   const newDisData2 =  newAddedOrder.filter(x => !selecteddet.find(s => s.chargecodeid === x.chargecodeid));
    //   newDisData2.map((val, i) => selecteddet.push(val));
    //   // newAddedOrder.map((val, i) => newSelectedOrders.push(val));
    //   setSelectedOrders(selecteddet);
    // } else if (codeSearch.length > 0 && newSelectedOrders?.length > 0) {
    //   // setSelectedOrders(newSelectedOrders);
    //   setSelectedOrders(selecteddet);
    // } else if (codeSearch.length > 0) {
    //   console.log("nothing is happened");
    // } else {
    //   // setSelectedOrders(newSelectedOrders);
    //   setSelectedOrders(selecteddet);
    // }
  }, [codeSearch.length, displayOrders, newAddedOrder]);

  React.useEffect(() => {
    getAllMasters();

    ChangeCodeDropDown();

    UomCodeDropDown();
  }, []);

  const getAllMasters = async () => {
    const response = await dispatch(actions.GET_ALL_MASTERS(""));

    setAllMasters(response?.payload?.data);
  };

  const ChangeCodeDropDown = async () => {
    const chargeDropDwn = await dispatch(actions.CHARGE_CODE_DROPDOWN());

    setChargeDropDwn(chargeDropDwn?.payload?.data);
  };

  const UomCodeDropDown = async () => {
    const uomDropDown = await dispatch(actions.UOM_DROPDOWN());

    setUomDropDwn(uomDropDown?.payload?.data);
  };

  // initail Table Date

  const getTableData = async () => {
    const patientId = LoadpatientData?.state?.patientId;

    const encounterId = LoadpatientData?.state?.encounter[counter]?._id ?? "";

    setEncounterNo(encounterId);

    const data = await dispatch(
      actions.GET_TABLE_DATA({ patientId, encounterId })
    );

    const tableData = displayTable(data?.payload?.data ?? []);

    setDisplayOrders(tableData);

    setStandardTableorders(tableData);
    getInsuranceName(encounterId);
    getFinanceData(encounterId);
  };

  const setTheTableData = useSelector(
    (state) => state?.billSettlementSlice?.table_data_read
  );

  // Handling the unbilled charges checkBox

  const handleUnBilled = (e) => {
    setUnBilledCheckBox(e.target.checked);

    const unBilledOrders = displayOrders.filter(
      (val, i) => val.billed === false
    );

    const standardUnBilled = standardTableorders.filter(
      (val, i) => val.billed === false
    );

    if (!e.target.checked) {
      let displayTableData = displayTable(setTheTableData?.data?.result ?? []);

      setDisplayOrders(displayTableData);

      setStandardTableorders(displayTableData);
    } else {
      setDisplayOrders(unBilledOrders);

      setStandardTableorders(standardUnBilled);
    }
  };

  const [roundOffval, setRoundOff] = useState(0.0);

  const getPriceDetails = async (newData) => {
    let payers = [];
    finData.map((val) => {
      payers.push({
        priority: val?.priority?._id,
        payerid: val?.payer,
        encounteraccountid:val?.encounteraccountid,
      });
    });
    let chargecodes = [];
    const checkedData = displayOrders.filter((val, i) => val?.checked === true);
    const addedDatas = JSON.parse(JSON.stringify(newAddedOrder));
    checkedData?.map((val) =>
      chargecodes.push({
        chargecode: val?.chargecodeid,
        qty: Number(val?.qty),
        patchargetrxid: val?.id,
        service: val?.service,
        servicedesc: val?.description,
        orderdatetime: val?.orderdatetime,
        chargeedit: val?.chargeedit,
        billstatus: val?.billed,
      })
    );
    addedDatas?.map((val) =>
      chargecodes.push({
        chargecode: val?.chargecodeid,
        qty: Number(val?.qty),
        patchargetrxid: val?.id,
        service: val?.service,
        servicedesc: val?.description,
        orderdatetime: getDateAndTime(new Date(new Date()) / 1000),
        chargeedit: false,
        billstatus: false,
      })
    );
    // newData?.map((val) =>
    if (newData) {
      chargecodes.push({
        chargecode: newData?.chargecodeid,
        qty: Number(newData?.qty),
        patchargetrxid: newData?.id,
      });
    }
    // );
    let payload = {
      facilityid: decodedUserinfo?.facilityid,
      patientid: LoadpatientData?.state?.patientId,
      encounter: encounterNo,
      payerids: payers,
      chargecodes: chargecodes,
      tenantid: decodedUserinfo?.tenantid,
      currencycode: decodedUserinfo?.currencyinfo?.currencycode,
      billdate: selectencounterDataList?.encounterStart,
    };

    let token = localStorage.getItem("JwtToken");

    await axios({
      method: "POST",

      headers: {
        "Content-Type": "application/json",

        jwtToken: `${token}`,
      },

      url: urls.loadBillDetails,

      data: payload,
    })
      .then((res) => {
        if (res.status === 200 && !res.data.error) {
          let sel = displayTable(res.data.response);

          // const selecteddet = JSON.parse(JSON.stringify(selectedOrders));

          // let updatedSelDet = [];
          // selecteddet.forEach((d, i) => {
          //   let val = sel.find((v) => v.chargecodeid === d.chargecodeid && v.id === d.id);
          //   updatedSelDet.push({
          //     service: val?.service ?? d.service,
          //     description: val?.description ?? d.description,
          //     orderRef: val?.orderRef ?? d.orderRef,
          //     date: val?.date ?? d.date,
          //     category: val?.category ?? d.category,
          //     qty: val?.qty ?? d.qty,
          //     uom: val?.uom ?? d.uom,
          //     // uomId: val?.chargeuom?._id,
          //     rate: val?.rate ?? d.rate,
          //     amt: d?.amt,
          //     status: val?.status ?? d.status,
          //     billed: val?.billed ?? d.billed,
          //     checked: d?.checked,
          //     id: d?.id,
          //     orderdispenseid: d?.orderdispenseid,
          //     orderrefid: d?.orderrefid,
          //     chargecodeid: val?.chargecodeid ?? d.chargecodeid,
          //     payerDtls: val?.payerDtls ?? d.payerDtls,
          //     packageorderid: val?.packageorderid ?? d.packageorderid,
          //     packorddesc: val?.packorddesc ?? d.packorddesc,
          //     gross: val?.gross ?? d.gross,
          //     net: val?.net ?? d.net,
          //     discount: val?.discount ?? d.discount,
          //     patientShare: val?.patientShare ?? d.patientShare,
          //     creditShare: val?.creditShare ?? d.creditShare,
          //     chargeedit:val?.chargeedit ?? d.chargeedit ?? false
          //   });
          // });
          // let newSel = sel.filter(x => !selecteddet.find(s => s.chargecodeid === x.chargecodeid && s.id === x.id));
          // newSel.forEach((d, i) => {
          //   updatedSelDet.push({
          //     service: d.service,
          //     description: d.description,
          //     orderRef: d.orderRef,
          //     date: d.date,
          //     category: d.category,
          //     qty: d.qty,
          //     uom: d.uom,
          //     rate: d.rate,
          //     amt: d?.amt,
          //     status: d.status,
          //     billed: d.billed,
          //     checked: d?.checked,
          //     id: d?.id,
          //     orderdispenseid: d?.orderdispenseid,
          //     orderrefid: d?.orderrefid,
          //     chargecodeid: d.chargecodeid,
          //     payerDtls: d.payerDtls,
          //     packageorderid: d.packageorderid,
          //     gross: d.gross,
          //     net: d.net,
          //     discount: d.discount,
          //     patientShare: d.patientShare,
          //     creditShare: d.creditShare,
          //     chargeedit: d.chargeedit ?? false
          //   });
          // });
          setSelectedOrders(sel);
          setActiveStep(1);
        } else {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: res.data.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          return false;
        }
      })
      .catch((error) => {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please contact to admin",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return false;
      });

    // setSelectedOrders(priceData);
  };

  // Proceed Button

  const handleProceed = async () => {
    if (true) {
      const orgId = localStorage.getItem("nameOfOrg");

      // const payerDropDwn = await dispatch(actions.PAYER_DROPDOWN());

      // const paymentDropDown = await dispatch(actions.PAYMENT_DROPDOWN());

      const roundOffValue = await dispatch(actions.BILL_ROUND_OFF({ orgId }));

      setRoundOff(roundOffValue?.payload?.data?.[0]?.parametervalue);
      // const selecteddet = JSON.parse(JSON.stringify(selectedOrders));

      let isSuccess = getPriceDetails();
      setBillNo({
        ...billNo,
        date: encounterDate,
      });

      // if(isSuccess){
      //   setActiveStep(activeStep + 1);
      // }
      // if (billNo?.billNum === null) {
      //   const billNom = await dispatch(actions.BILL_NO());

      //   setBillNo({
      //     ...billNo,

      //     billNum: billNom?.payload?.data?.Result?.billno ?? null,

      //     date: getDateAndTime(new Date(new Date()) / 1000),
      //   });
      // }
    } else {
      props.alert.setSnack({
        open: true,

        severity: AlertProps.severity.error,

        msg: "Please select atleast one order",

        vertical: AlertProps.vertical.top,

        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  // Previous Button

  const handlePrevious = () => {
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1);

      setAddNewSwitch(false);

      const oldSelectedOrder = selectedOrders.filter(
        (val, i) => val?.isNew === undefined
      );

      const isChecked = oldSelectedOrder.filter(
        (val, i) => val?.checked === true
      );

      const unBilledDisplayOrder = displayOrders.filter(
        (val, i) => val?.billed === false
      );

      if (isChecked.length === unBilledDisplayOrder.length) {
        setAllChecked(true);
      } else {
        setAllChecked(false);
      }
    }
  };

  // Add new Button

  const handleAddnew = () => {
    setAddNewSwitch(true);
  };

  // Add New order

  const handleAddNewOrder = async (data, isContinue = false) => {
    const addNewOrder = JSON.parse(JSON.stringify(selectedOrders));

    if (
      addNewOrder.find((x) => x.chargecodeid == data.chargecodeid) &&
      !isContinue
    ) {
      setAlertData({
        open: true,
        data: {
          type: 3,
          alertText:
            "Duplicate order is entered. If you continue, the manual changes will reset. Do you still want to continue?",
          val: data,
          action: "A",
        },
      });
      // props.alert.setSnack({
      //   open: true,
      //   severity: AlertProps.severity.error,
      //   msg: "Selected Charge Code already exist",
      //   vertical: AlertProps.vertical.top,
      //   horizontal: AlertProps.horizontal.right,
      // });
    } else {
      const newData = JSON.parse(JSON.stringify(newAddedOrder));
      // const newData =[];
      newData.push(data);

      // newlyAdded.push(updatedSelDet);

      if (isContinue) {
        getPriceDetails(data);
        setNewlyAdded(newData);
      } else {
        const selecteddet = JSON.parse(JSON.stringify(selectedOrders));

        let payers = [];
        finData.map((val) => {
          payers.push({
            priority: val?.priority?._id,
            payerid: val?.payer,
            encounteraccountid:val?.encounteraccountid,
          });
        });
        let chargecodes = [];
        chargecodes.push({
          chargecode: data?.chargecodeid,
          qty: Number(data?.qty),
          patchargetrxid: data?.id,
          service: data?.service,
          servicedesc: data?.description,
          orderdatetime: getDateAndTime(new Date(new Date()) / 1000),
          chargeedit: false,
          billstatus: false,
        });
        let payload = {
          facilityid: decodedUserinfo?.facilityid,
          patientid: LoadpatientData?.state?.patientId,
          encounter: encounterNo,
          payerids: payers,
          chargecodes: chargecodes,
          tenantid: decodedUserinfo?.tenantid,
          currencycode: decodedUserinfo?.currencyinfo?.currencycode,
          billdate: selectencounterDataList?.encounterStart,
        };

        let token = localStorage.getItem("JwtToken");
        // const updatedSelDet = [];
        const price = await AxiosCall(
          "post",
          urls.loadBillDetails,
          payload,
          {
            "Content-Type": "application/json",
            jwtToken: `${token}`,
          }
        );
        // const newlyAdded = addNewOrder.filter((val, i) => val?.isNew === true);
        if (price.statusCode && !price.error) {
          const sel = displayTable(price.response);
          sel?.forEach((d, i) => {
            selecteddet.push(d);
          });
          setSelectedOrders(selecteddet);
          setNewlyAdded(newData);
        } else {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg:
              price.message ??
              price?.response?.data?.message ??
              "Please contact to admin",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }

        // sel?.forEach((d, i) => {
        //   newlyAdded.push({
        //     service: d.service ?? "---",
        //     description: d.description ?? "no-desc",
        //     orderRef: d.orderRef ?? "no-ref",
        //     date: d.date,// ?? createDate(new Date(new Date()) / 1000),
        //     category: d.category ?? "--nocat--",
        //     qty: d.qty,
        //     uom: d.uom,
        //     rate: d.rate,
        //     amt: d?.amt,
        //     status: d.status,
        //     billed: d.billed,
        //     checked: d?.checked,
        //     id: d?.id,
        //     orderdispenseid: d?.orderdispenseid,
        //     orderrefid: d?.orderrefid,
        //     chargecodeid: d.chargecodeid,
        //     payerDtls: d.payerDtls,
        //     packageorderid: d.packageorderid,
        //     packorddesc: d.packorddesc,
        //     gross: d.gross,
        //     net: d.net,
        //     discount: d.discount,
        //     patientShare: d.patientShare,
        //     creditShare: d.creditShare,
        //     chargeedit: d.chargeedit ?? false,
        //     isNew: true
        //   });
        // });
      }

      // setSelectedOrders(addNewOrder);

      setAddNewSwitch(false);
    }
  };

  // Close New Order Bar

  const handleRemoveAddnew = () => {
    setAddNewSwitch(false);
  };

  // handle Check Box

  const handleCheckBox = (e, row, rowIndex) => {
    let newOrders = JSON.parse(JSON.stringify(displayOrders));

    newOrders[rowIndex].checked = e.target.checked;

    let standardTableOrdersData = JSON.parse(
      JSON.stringify(standardTableorders)
    );

    const standardIndex = standardTableOrdersData.findIndex(
      (v) => v?.id === row?.id
    );

    standardTableOrdersData[standardIndex].checked = e.target.checked;

    setDisplayOrders(newOrders);

    setStandardTableorders(standardTableOrdersData);
  };

  // calculated selectedOrders

  const handleCalculations = (results, rowIndex) => {
    if (
      typeof results === "object" &&
      !Array.isArray(results) &&
      results !== null
    ) {
      const editedText = JSON.parse(JSON.stringify(selectedOrders));

      editedText.splice(rowIndex, 1, results);

      setSelectedOrders(editedText);
    } else {
      setSelectedOrders(results);
    }
  };

  // select all unbilled orders

  const handleHeaderCheckBox = (e) => {
    let newOrders = JSON.parse(JSON.stringify(displayOrders));

    newOrders.map((val, i) => {
      if (val.billed === false) {
        return (val.checked = e.target.checked);
      } else {
        return (val.checked = false);
      }
    });

    setAllChecked(e.target.checked);

    setDisplayOrders(newOrders);

    setStandardTableorders(newOrders);
  };

  // deleting the selected orders multiple data
  const deleteSelectedMultiData = (row) => {
    const selectedOrder = displayOrders?.filter((val, i) => val.id === row.id);
    const selectedIndex = displayOrders?.indexOf(selectedOrder[0]);
    const selecteddet = JSON.parse(JSON.stringify(selectedOrders))?.filter(
      (val, i) => val.id !== row.id
    );

    if (selectedIndex > -1) {
      let newOrders = JSON.parse(JSON.stringify(displayOrders));
      newOrders[selectedIndex].checked = false;
      setDisplayOrders(newOrders);
      setStandardTableorders(newOrders);
      setSelectedOrders(selecteddet);
    }
  };

  // deleting the selected orders

  const handleDeleteSelected = (row, rowIndex) => {
    const selectedOrder = displayOrders?.filter((val, i) => val.id === row.id);

    const selectedIndex = displayOrders?.indexOf(selectedOrder[0]);

    const newOrder = JSON.parse(JSON.stringify(selectedOrders));

    if (
      (newOrder?.filter((val, i) => val.id && val.id === row.id)).length > 1
    ) {
      setAlertData({
        open: true,
        data: {
          type: 3,
          alertText:
            "If you delete this record, the entire request will delete together. Do you still want to continue?",
          val: row,
          action: "D",
        },
      });
    } else {
      newOrder.splice(rowIndex, 1);

      setSelectedOrders(newOrder);
      if (selectedIndex > -1) {
        let newOrders = JSON.parse(JSON.stringify(displayOrders));
        newOrders[selectedIndex].checked = false;
        setDisplayOrders(newOrders);
        setStandardTableorders(newOrders);
      }
      const addedOrder = newAddedOrder?.filter(
        (val, i) =>
          val.chargecodeid === row.chargecodeid &&
          val.id === row.id &&
          val.qty == row.qty
      );
      const addedIndex = newAddedOrder?.indexOf(addedOrder[0]);
      if (addedIndex > -1) {
        const newAddedOrderList = JSON.parse(JSON.stringify(newAddedOrder));
        newAddedOrderList.splice(addedIndex, 1);
        setNewlyAdded(newAddedOrderList);
      }
    }
  };

  const handlePayerSplitUpdate = (i, row, payerSplit) => {
    const total = payerSplit.reduce(
      (a, v) => (a = a + Number(v.payershare)),
      0
    );
    if (total == row.creditShare) {
      const payers = payerSplit.filter(
        (p) => (Number.isNaN(p?.payershare) ? 0 : Number(p?.payershare)) !== 0
      );
      const payerDtls = [];
      payers.map((v) => {
        payerDtls.push({
          netamt: Number(v?.netamt),
          billrate: Number(v?.billrate),
          grossamt: Number(v?.grossamt),
          payerid: v?.payerid,
          payershare: Number(v?.payershare) ?? 0,
          _id: "",
          billqty: v?.billqty ?? 0,
          discamt: v?.discamt,
          chargecodeid: v?.chargecodeid,
          maxlimitamt: v?.maxlimitamt ?? 0,
          otherpayershare:
            Number(v?.grossamt) - Number(v?.payershare) ??
            v?.otherpayershare ??
            0,
          copayamt: v?.copayamt ?? 0,
          planid: v?.planid,
          priority: v?.priority,
          encounteraccountid: v?.encounteraccountid,
        });
      });
      const self = finData?.filter((f) => f?.payer === process.env.REACT_APP_PAYER_SELFPAY);
      const selfOrdrd = [].concat(self)
        .sort((a, b) => a.priority?.display > b.priority?.display ? 1 : -1)
      payerDtls.push({
        netamt: Number(row.net),
        billrate: Number(row?.gross),
        grossamt: Number(row?.gross),
        payerid: selfOrdrd?.[0]?.payer,
        payershare: row.patientShare ?? 0,
        _id: "",
        billqty: 0,
        discamt: row?.discount,
        chargecodeid: row?.chargecodeid,
        maxlimitamt: 0,
        otherpayershare: Number((row?.net - row?.patientShare).toFixed(2)),
        copayamt: 0,
        planid: selfOrdrd?.[0]?.planid,
        priority: selfOrdrd?.[0]?.priority?.display,
        encounteraccountid: selfOrdrd?.[0]?.encounteraccountid,
      });
      row.payerDtls = payerDtls;
      const editOrders = JSON.parse(JSON.stringify(selectedOrders));
      // const addedOrder = editOrders?.filter((val, i) => val.chargecodeid === row.chargecodeid && val.id === row.id);
      // const addedIndex = editOrders?.indexOf(addedOrder[0]);
      if (i > -1) {
        editOrders[i] = row;
        setSelectedOrders(editOrders);
      }
      setOpenSplitPayer(false);
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Sum of payer split not equal to credit share",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };
  // Onchange of the discount and the patient Share

  const handleTableTextChange = (e, row, rowIndex, type) => {
    const isNumber = Number.isNaN(row?.creditShare);

    const discounted = Number.isNaN(
      type == "discount" ? e.target.value : row?.discount
    );

    const totalCounted = Number.isNaN(
      type == "patientShare" ? e.target.value : row?.patientShare
    );

    const IndexValue = e.target.value == "" ? 0 : e.target.value;
    const total =
      Number(
        discounted ? 0 : type == "discount" ? e.target.value : row?.discount
      ) +
      Number(
        totalCounted
          ? 0
          : type == "discount"
          ? Number(row?.gross) - Number(e.target.value)
          : type == "patientShare"
          ? e.target.value
          : row?.patientShare
      );

    const patientShareValue = () => {
      if (Number(IndexValue) > Number(row?.net) && type === "patientShare") {
        return false;
      } else if (
        type === "discount" &&
        Number(row?.gross) <= Number(row?.patientShare)
      ) {
        return true;
      } else if (
        type === "discount" &&
        Number(row?.gross) < Number(IndexValue)
      ) {
        return false;
      } else if (
        Number(row?.discount) > Number(row?.qty) * Number(IndexValue) &&
        type === "rate"
      ) {
        return false;
      } else {
        return true;
      }
    };

    if (
      IndexValue >= 0 &&
      IndexValue !== "-" &&
      total <= Number(row?.gross) &&
      patientShareValue()
    ) {
      if (type === "rate") {
        const editOrders = JSON.parse(JSON.stringify(selectedOrders));

        editOrders[rowIndex][type] = parseFloat(e.target.value);

        editOrders[rowIndex]["gross"] = IndexValue * row?.qty;
        editOrders[rowIndex]["patientShare"] =
          editOrders[rowIndex]["gross"] - row?.discount;

        editOrders[rowIndex] = { ...editOrders[rowIndex], isEdited: true };

        const addedOrder = newAddedOrder?.filter(
          (val, i) => val.id === editOrders[rowIndex]["id"]
        );
        const editaddedOrders = JSON.parse(JSON.stringify(newAddedOrder));
        const addedIndex = newAddedOrder?.indexOf(addedOrder[0]);
        if (addedIndex > -1) {
          editaddedOrders[addedIndex] = editOrders[rowIndex];
          setNewlyAdded(editaddedOrders);
        }

        setSelectedOrders(editOrders);
      } else if (type === "discount") {
        const editOrders = JSON.parse(JSON.stringify(selectedOrders));

        editOrders[rowIndex][type] = parseFloat(e.target.value);

        editOrders[rowIndex]["patientShare"] =
          // e.target.value > 0
          // ? ((row?.gross - e.target.value) / 100) * sharePercent?.patientShare
          // :
          row?.gross - IndexValue;

        editOrders[rowIndex] = { ...editOrders[rowIndex], isEdited: true };

        const addedOrder = newAddedOrder?.filter(
          (val, i) => val.id === editOrders[rowIndex]["id"]
        );
        const editaddedOrders = JSON.parse(JSON.stringify(newAddedOrder));
        const addedIndex = newAddedOrder?.indexOf(addedOrder[0]);
        if (addedIndex > -1) {
          editaddedOrders[addedIndex] = editOrders[rowIndex];
          setNewlyAdded(editaddedOrders);
        }

        setSelectedOrders(editOrders);
      } else {
        const editOrders = JSON.parse(JSON.stringify(selectedOrders));

        editOrders[rowIndex][type] = parseFloat(e.target.value);

        editOrders[rowIndex] = { ...editOrders[rowIndex], isEdited: true };

        const addedOrder = newAddedOrder?.filter(
          (val, i) => val.id === editOrders[rowIndex]["id"]
        );
        const editaddedOrders = JSON.parse(JSON.stringify(newAddedOrder));
        const addedIndex = newAddedOrder?.indexOf(addedOrder[0]);
        if (addedIndex > -1) {
          editaddedOrders[addedIndex] = editOrders[rowIndex];
          setNewlyAdded(editaddedOrders);
        }

        setSelectedOrders(editOrders);
      }
    } else {
      props.alert.setSnack({
        open: true,

        severity: AlertProps.severity.error,

        msg: "The credit share cant be a negative value",

        vertical: AlertProps.vertical.top,

        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  // The Billing component Switch

  const getStepperComponents = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <ViewBilling
            displayOrders={displayOrders}
            handleCheckBox={handleCheckBox}
            handleHeaderCheckBox={handleHeaderCheckBox}
            allChecked={allChecked}
            codeSearch={codeSearch}
            standardTableorders={standardTableorders}
          />
        );

      case 1:
        return (
          <BillingSettlement
            addNewSwitch={addNewSwitch}
            setSharePercent={setSharePercent}
            sharePercent={sharePercent}
            handleRemoveAddnew={handleRemoveAddnew}
            selectedOrders={selectedOrders}
            handleCalculations={handleCalculations}
            handleAddNewOrder={handleAddNewOrder}
            handleDeleteSelected={handleDeleteSelected}
            handleTableTextChange={handleTableTextChange}
            billNo={billNo}
            setPaymentDetails={setPaymentDetails}
            setCalculationResultData={setCalculationResultData}
            calculationResultData={calculationResultData}
            // handlePayer={handlePayer}
            payer={payer}
            finData={finData}
            handlePayementDetails={handlePayementDetails}
            paymentDetails={paymentDetails}
            receipt={receipt}
            roundOffval={roundOffval}
            setRoundOffAmnt={setRoundOffAmnt}
            roundOffAmnt={roundOffAmnt}
            allMasters={allMasters}
            chargeDropDwn={chargeDropDwn}
            uomDropDwn={uomDropDwn}
            encounterId={encounterNo}
            handleOpenPopup={handleOpenPopup}
            openSplitPayer={openSplitPayer}
          />
        );

      default:
        return "Unknown step";
    }
  };

  // cancel the billing Process

  const handleCancel = () => {
    navigate(AppRoutes.dashboard);
  };

  // Search code and description in the display table

  const handleCodeSearch = async (value) => {
    const patientId = LoadpatientData?.state?.patientId;

    const encounterId = LoadpatientData?.state?.encounter[counter]?._id ?? "";

    setCodeSearch(value);

    const data = await dispatch(
      actions.SEARCH_CODE_DESC({ patientId, encounterId, value })
    );

    const constructedData = displayTable(data?.payload?.data);

    const newData = constructedData.map(
      (val) => standardTableorders.find((v) => v.id === val.id) || val
    );

    setDisplayOrders(newData);
  };

  //handle Payer
  const loadDefaultValues = async () => {
    const patientCardDetails = LoadpatientData?.state;
    let payload = {
      screenid: process.env.REACT_APP_BILLING_DETAILS_SCREEN_ID,
      tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
      filtervalues: {
        // patientid: "Patient/13498"
        patientid: patientCardDetails?.patientId,
      },
    };
    let token = localStorage.getItem("JwtToken");

    let register = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      data: JSON.stringify(payload),
    })
      .then((res) => {
        if (
          res?.data?.response?.screenId ===
          process.env.REACT_APP_BILLING_DETAILS_SCREEN_ID
        ) {
          const defaultValues = res?.data?.response?.defaultValues;
          const stateNames = ["payer"];
          const stateValues = {};
          defaultValues.forEach((s) => {
            if (stateNames.includes(s.stateName)) {
              stateValues[s.stateName] = s.value;
            }
          });
          setPayer(stateValues?.payer);
        }
      })
      .catch((error) => {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please contact to admin",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      });
  };
  // const handlePayer = (e) => {
  //   setPayer(e);
  //   // if(activeStep>0){
  //     const selecteddet = JSON.parse(JSON.stringify(selectedOrders));
  //     getPriceDetails(selecteddet,selecteddet);
  //   // }
  // };

  const billingDetails = selectedOrders?.map((val, i) => {
    if (typeof val === "object" && !Array.isArray(val) && val !== null) {
      const billrefno = {
        billrefno: " ",
      };

      return { ...val, ...billrefno };
    } else {
      return val;
    }
  });

  const billDetailSave = billingDetails.map((val) => {
    return {
      _id: "",
      facilityid: decodedUserinfo?.facilityid,
      tenantid: decodedUserinfo?.tenantid,
      dispenseid: val?.orderdispenseid ? val?.orderdispenseid : " ",
      patientid: LoadpatientData?.state?.patientId,
      encounterid: encounterNo,
      billrefno: "",
      patchargetrxid: val?.id ? val?.id : " ",
      orderrefid: val?.orderrefid ? val?.orderrefid : " ",
      grossamt: Number.isNaN(val?.gross) ? 0 : Number(val?.gross),
      discamt: Number.isNaN(val?.discount) ? 0 : Number(val?.discount),
      netamt: Number.isNaN(val?.net) ? 0 : Number(val?.net),
      patientshare: Number.isNaN(val?.patientShare)
        ? 0
        : Number(val?.patientShare),
      payershare: Number.isNaN(val?.creditShare) ? 0 : Number(val?.creditShare),
      billdstatus: true,
      chargecodeid: val?.chargecodeid,
      billqty: parseInt(val?.qty),
      billrate: Number.isNaN(val?.rate) ? 0 : Number(val?.rate),
      payerDtls: val?.payerDtls,
      packageorderid: val?.packageorderid,
      packorddesc: val?.packorddesc,
      activestatus: true,
    };
  });

  const successfun2 = async () => {
    let payload = {
      ticketId: location?.state?.ticketId,

      userAction: location?.state?.actionName,
    };

    let token = localStorage.getItem("JwtToken");

    let register = await axios({
      method: "POST",

      headers: {
        "Content-Type": "application/json",

        jwtToken: `${token}`,
      },

      url: process.env.REACT_APP_PERFORMUSERACTION_API,

      data: payload,
    })
      .then((res) => {
        // if (res.status === 200 && !res.data.error) {
        // props?.alert?.setSnack({
        //   open: true,
        //   severity: AlertProps.severity.success,
        //   msg: "Approved successfully",
        //   vertical: AlertProps.vertical.top,
        //   horizontal: AlertProps.horizontal.center,
        //   tone: true,
        // });
        // } else {
        //   props?.alert?.setSnack({
        //     open: true,
        //     severity: AlertProps.severity.error,
        //     msg: res.data.message,
        //     vertical: AlertProps.vertical.top,
        //     horizontal: AlertProps.horizontal.center,
        //     tone: true,
        //   });
        // }
      })

      .catch((error) => {
        // props?.alert?.setSnack({
        //   open: true,
        //   severity: AlertProps.severity.error,
        //   msg: "Please contact to admin",
        //   vertical: AlertProps.vertical.top,
        //   horizontal: AlertProps.horizontal.center,
        //   tone: true,
        // });
      });

    // }
  };

  const generateAndOpenJasperReport = async () => {
    let JwtToken = localStorage.getItem("JwtToken");
    let header = { JwtToken: JwtToken };
    const payload = {
      // reportid: "d5e42b5b-fe82-44ca-8ffc-d83038b02635",

      // inputparams: {
      //   "@loginuserid": decodedUserinfo?.practitionerid,

      //   "@patientid": LoadpatientData?.state?.patientId,

      //   "@encounterid": encounterNo,
      // },

      reportid: "afd2cd92-4dea-422e-8633-876eb859a136",
      inputparams: {
        "@patientid": LoadpatientData?.state?.patientId,
        "@encounterid": encounterNo,
        "@facilityid": decodedUserinfo?.facilityid,
        "@tenantid": decodedUserinfo?.tenantid,
      },

      result: [],
    };

    AxiosCall("post", urls.jasperReport, payload, header).then(async (res) => {
      const response = await axios.get(res?.downloadUrl, {
        method: "GET",
        responseType: "blob",
      });
      const pdfBlob = new Blob([response.data], {
        type: "application/pdf",
      });
      const pdfDataUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfDataUrl, "_blank");
    });

    navigate(AppRoutes.dashboard);
  };
  function dateToEpoch(dateStr) {
    var dateObj = new Date(dateStr);
    var epochTime = Math.floor(dateObj.getTime() / 1000);
    return epochTime;
  }
  const handleSave = async () => {
    setLoading(true);
    let payers = [];
    billDetailSave.map((val) => {
      val.payerDtls.map((p) => {
        if (p?.payerid !== process.env.REACT_APP_PAYER_SELFPAY) {
          payers.push(p?.payerid);
        }
      });
    });
    const units = [
      "",
      "SATU",
      "DUA",
      "TIGA",
      "EMPAT",
      "LIMA",
      "ENAM",
      "TUJUH",
      "LAPAN",
      "SEMBILAN",
    ];
    const teens = [
      "",
      "SEPULUH",
      "SEBELAS",
      "DUA BELAS",
      "TIGA BELAS",
      "EMPAT BELAS",
      "LIMA BELAS",
      "ENAM BELAS",
      "TUJUH BELAS",
      "LAPAN BELAS",
      "SEMBILAN BELAS",
    ];
    const tens = [
      "",
      "SEPULUH",
      "DUA PULUH",
      "TIGA PULUH",
      "EMPAT PULUH",
      "LIMA PULUH",
      "ENAM PULUH",
      "TUJUH PULUH",
      "LAPAN PULUH",
      "SEMBILAN PULUH",
    ];

    const convertToWords = (number, malay = false) => {
      if (malay) {
        return convertToMalay(number);
      } else {
        let numToWords = numberToWords.toWords(number);
        return numToWords.toUpperCase();
      }
    };

    const convertToMalay = (number) => {
      if (number < 10) {
        return units[number];
      } else if (number < 20) {
        return teens[number - 10];
      } else if (number < 100) {
        return tens[Math.floor(number / 10)] + " " + units[number % 10];
      } else if (number < 1000) {
        return (
          units[Math.floor(number / 100)] +
          " RATUS " +
          convertToMalay(number % 100)
        );
      } else if (number < 1000000) {
        return (
          convertToMalay(Math.floor(number / 1000)) +
          " RIBU " +
          convertToMalay(number % 1000)
        );
      } else if (number < 1000000000) {
        return (
          convertToMalay(Math.floor(number / 1000000)) +
          " JUTA " +
          convertToMalay(number % 1000000)
        );
      } else {
        return "Number out of range";
      }
    };
    console.log(convertToWords(calculationResultData?.net)); //English
    console.log(convertToWords(calculationResultData?.net, true)); //words in malay send true get words in malay
    const billHeaderSave = {
      _id: "",
      activestatus: true,
      billno: billNo?.billNum ?? "",
      // billdate: dateToEpoch(billNo?.date)??"",
      billdate: selectencounterDataList?.encounterStart ?? "",
      tenantid: decodedUserinfo?.tenantid,
      facilityid: decodedUserinfo?.facilityid,
      patientid: LoadpatientData?.state?.patientId,
      encounterid: encounterNo,
      totgrossamt: calculationResultData?.gross,
      totdiscamt: calculationResultData?.discount,
      totnetamt: calculationResultData?.net,
      totpatientshare: Number(
        (
          calculationResultData?.patientShare + Number(roundOffAmnt?.roundOff)
        ).toFixed(2)
      ),
      totpayershare: calculationResultData?.creditShare,
      billhstatus: true,
      amtineng: convertToWords(calculationResultData?.net),
      amtinlocal: convertToWords(calculationResultData?.net, true),
      payerid: payers,
      RCM_billdtls: billDetailSave,
      // RCM_Paymentdtls: [
      //   {
      //     _id: "",
      //     patientid: LoadpatientData?.state?.patientId,
      //     encounterid: encounterNo,
      //     billhdrid: "",
      //     receiptno: receipt?.receiptNo,
      //     receiptdate: getUnixCodeDate(receipt?.date),
      //     billamt: Number(
      //       (
      //         Number(calculationResultData?.patientShare ?? 0) +
      //         Number(roundOffAmnt?.roundOff ?? 0)
      //       ).toFixed(2)
      //     ),
      //     paidamt: Number(
      //       (
      //         Number(paymentDetails?.settleNowValue ?? 0) +
      //         Number(roundOffAmnt?.roundOff ?? 0)
      //       ).toFixed(2)
      //     ),
      //     balamt: paymentDetails?.balanceDue,
      //     paymentdtls: [
      //       {
      //         paymentmode: paymentDetails?.paymentType?._id,
      //         docrefno: paymentDetails?.docRefNo,
      //         docrefdate: paymentDetails?.docRefDate,
      //         remarks: paymentDetails?.remarks,
      //         cardno: Number(paymentDetails?.card),
      //         expdate:
      //           paymentDetails?.expiry?.length === 0
      //             ? 0
      //             : paymentDetails?.expiry,
      //         // cardtype: "RUPAY",
      //       },
      //     ],
      //     settlenow: paymentDetails?.settleNow,
      //     roundoffamt: parseFloat(roundOffAmnt?.roundOff),
      //   },
      // ],
    };

    // const validation = validate(billHeaderSave, paymentDetails);

    // console.log("validation", validation);

    // if (validation?.status) {
    let token = localStorage.getItem("JwtToken");

    let register = await axios({
      method: "POST",

      url: urls.saveBill,

      headers: {
        "Content-Type": "application/json",

        jwtToken: `${token}`,
      },

      data: JSON.stringify([billHeaderSave]),
    })
      .then((res) => {
        if (res.status === 200 && !res.data.error) {
          setLoading(false);
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "save successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          const patientCardDetails = LoadpatientData?.state;
          const encounterDetails = LoadpatientData?.state?.encounter ?? "";
          const billHrdNo = res.data.response[0]._id;
          successfun2();
          // generateAndOpenJasperReport();
          navigate(AppRoutes.paymentCollections, {
            state: {
              patientid: patientCardDetails?.patientId,
              encounterid: encounterNo,
              selectencounterDataList: selectencounterDataList,
            },
          });

          // navigate(AppRoutes.billingSummary, {
          //   // encounterNo: `EN - ${location?.state?.data?.encounter[counter]?._key}`,
          //   // demoRecieptDetails: "demoRecieptDetails",
          //   state: {
          //     billHrdNo: billHrdNo,
          //     payload: LoadpatientData?.state,
          //   },
          // });
        } else {
          props?.alert?.setSnack({
            open: true,

            severity: AlertProps.severity.error,

            msg: res.data.message,

            vertical: AlertProps.vertical.top,

            horizontal: AlertProps.horizontal.center,

            tone: true,
          });
          setLoading(false);
        }
      })

      .catch((error) => {
        props?.alert?.setSnack({
          open: true,

          severity: AlertProps.severity.error,

          msg: "Please contact to admin",

          vertical: AlertProps.vertical.top,

          horizontal: AlertProps.horizontal.center,

          tone: true,
        });
        setLoading(false);
      });

    // const billHeaderId =billHeaderResult?.payload?.data?.Result[0]?.properties?.doc?._id;

    // if (billHeaderId) {

    //   const billingDetails = selectedOrders?.map((val, i) => {

    //     if (typeof val === "object" && !Array.isArray(val) && val !== null) {

    //       const billrefno = {

    //         billrefno: billHeaderId,

    //       };

    //       return { ...val, ...billrefno };

    //     } else {

    //       return val;

    //     }

    //   });

    //   const billDetailsSave = billingDetails.map((val) => {

    //     return {

    //       dispenseid: val?.orderdispenseid,

    //       patientid: props?.location?.state?.data?._id,

    //       encounterid: props?.location?.state?.data?.encounter[counter]?._id,

    //       billrefno: billHeaderId,

    //       patchargetrxid: val?.id,

    //       orderrefid: val?.orderrefid,

    //       grossamt: Number(val?.gross),

    //       discamt: Number(val?.dicount),

    //       netamt: Number(val?.net),

    //       patientshare: Number(val?.patientShare),

    //       payershare: Number(val?.creditShare),

    //       billdstatus: true,

    //       chargecodeid: val?.chargecodeid,

    //       billqty: parseInt(val?.qty),

    //       billuom: val?.uomId,

    //       billrate: val?.rate,

    //     };

    //   });

    //   const billDetailsResult = await dispatch(

    //     actions.BILL_DETAILS_SAVE({ billDetailsSave })

    //   );

    //   const paymentPayload = {

    //     patientid: props?.location?.state?.data?._id,

    //     encounterid: props?.location?.state?.data?.encounter[counter]?._id,

    //     billhdrid: billHeaderId,

    //     receiptno: receipt?.receiptNo,

    //     receiptdate: getUnixCodeDate(receipt?.date),

    //     billamt: (

    //       calculationResultData?.patientShare + Number(roundOffAmnt?.roundOff)

    //     ).toFixed(2),

    //     paidamt: (

    //       Number(paymentDetails?.settleNowValue) +

    //       Number(roundOffAmnt?.roundOff)

    //     ).toFixed(2),

    //     balamt: paymentDetails?.balanceDue,

    //     roundoffamt: roundOffAmnt?.roundOff,

    //     paymentdtls: [

    //       {

    //         paymentmode: paymentDetails?.paymentType?.label,

    //         docrefno: paymentDetails?.docRefNo,

    //         docrefdate: paymentDetails?.docRefDate,

    //         remarks: paymentDetails?.remarks,

    //         cardno: Number(paymentDetails?.card),

    //         expdate:

    //           paymentDetails?.expiry?.length === 0

    //             ? 0

    //             : paymentDetails?.expiry,

    //       },

    //     ],

    //     settlenow: paymentDetails?.settleNow,

    //   };

    //   const paymentResult = await dispatch(

    //     actions.PAYMENT_SAVE({ paymentPayload })

    //   );

    //   const billIds = selectedOrders.map((val, i) => {

    //     return val?.id;

    //   });

    //   // const billStatusResult = await dispatch(

    //   //   actions.BILL_STATUS_SAVE({ billIds })

    //   // );

    //   // const demoRecieptDetails = await dispatch(

    //   //   actions.BILL_RECEIPT_READ({ billHeaderId })

    //   // );

    // }
    // } else {
    //   props.alert.setSnack({
    //     open: true,

    //     severity: AlertProps.severity.error,

    //     msg: validation?.msg,

    //     vertical: AlertProps.vertical.top,

    //     horizontal: AlertProps.horizontal.right,
    //   });
    // }
  };
  const handleEncounterSelect = async (val) => {
    const patientId = LoadpatientData?.state?.patientId;
    setSelectEncounterDataList(val);
    const encounterId = val?.encounterId;
    setEncounterNo(encounterId);
    setEncounterDate(createDate(val?.encounterStart));
    const data = await dispatch(
      actions.GET_TABLE_DATA({ patientId, encounterId })
    );

    const tableData = displayTable(data?.payload?.data ?? []);

    setStandardTableorders(tableData);

    setDisplayOrders(tableData);
    getFinanceData(encounterId);
  };
  const changeState = (key, data) => {
    console.log({ data });
  };

  const getAge = (date) => {
    return (
      Math.floor(
        moment(new Date()).diff(moment(date, "DD/MM/YYYY"), "years", true)
      ) + " Yrs"
    );
  };
  const getNextSet = async () => {
    let encCount = encPageCount + 10;
    const queryData = personRequests.getDetailsAgainstPatientSearchByIsCashier(
      0,
      0,
      "",
      patientCardDetails?.id,
      //app?.field2,
      "",
      "",
      "",
      "",
      "",
      getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
      encCount,
      10
    );
    const SearchUserData = await fetchData(queryData, urls.readQdmQueries);
    if (!SearchUserData || SearchUserData.length === 0) {
      setIsdisableright(true);
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Encounter data not Found",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else {
      let lista = {};
      SearchUserData?.forEach((v, i) => {
        Object.assign(lista, {
          idnumber: v?.identifier[0]?.value,
          idsourcecode: v?.identifier[0]?.code,
          address: v?.address,
          age: v?.age,
          gender: v?.gender,
          email:
            v?.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
              ?.value ?? "",
          name: makeName(v?.name?.[0] || {}) || "",
          id: v?.patmrn ?? "",
          dis: `${
            (v?.gender + ", " ?? "") +
            (v?.birthDate ? getAge(v?.birthDate) : "")
          }
              ${v?.telecom[0]?.value ? " | " + v?.telecom[0]?.value : ""}`,
          encounter: v?.encounter,
          mobileNumber:
            v?.telecom?.find((tel) => tel?.system === "CodingMaster/10473")
              ?.value ||
            v.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
              ?.value ||
            "",
          patientId: v?._id,
          img:
            getImgUrl(v?.photo?.[0]?.fileid) ||
            "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
        });
      });
      const list = lista?.encounter?.map((val, i) => ({
        encstatus: val?.encstatus,
        encounterId: val?._id,
        encounterName: val?._key ? `EN - ${val?._key}` : "",
        type: val?.type?.shortDesc ?? "",
        speciality: val?.practitioner_details?.speciality?.display ?? "-",
        encounterStart: val?.encounterdate ?? "",
        encounterEnd: val?.encounterenddate ?? "",
        location: val?.location?.[i]?.physicalType ?? "",
        doctorName: val?.practitioner_details?.idname,
      }));
      setIsdisableleft(false);
      setencPageCount(encCount);
      setEncounterDataList(list);
      setcount(count + 1);
    }
  };

  const getPreviousSet = async () => {
    let encPagecount = encPageCount === 0 ? 0 : encPageCount - 10;
    const queryData = personRequests.getDetailsAgainstPatientSearchByIsCashier(
      0,
      0,
      "",
      patientCardDetails?.id,
      //app?.field2,
      "",
      "",
      "",
      "",
      "",
      getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"facilityid"}),
      encPagecount,
      10
    );
    const SearchUserData = await fetchData(queryData, urls.readQdmQueries);
    if (!SearchUserData || SearchUserData.length === 0) {
      encPagecount = encPageCount + 2;
      setencPageCount(encPagecount === 0 ? 0 : encPagecount);
      setIsdisableleft(true);
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Encounter data not Found",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    } else {
      console.log(SearchUserData, "SearchUserDataSearchUserData");
      let lista = {};
      // encounterId:val?._id,
      // encounterName: val?._key ? `EN - ${val?._key}` : "",
      // type: val?.type?.shortDesc ?? "",
      // speciality: val?.practitioner_details?.speciality?.display ?? "-",
      // encounterStart: val?.encounterdate ?? "",
      // encounterEnd: val?.encounterenddate ?? "",
      // location: val?.location?.[i]?.physicalType ?? "",
      // doctorName: val?.practitioner_details?.idname,
      SearchUserData?.forEach((v, i) => {
        Object.assign(lista, {
          idnumber: v?.identifier[0]?.value,
          idsourcecode: v?.identifier[0]?.code,
          address: v?.address,
          age: v?.age,
          gender: v?.gender,
          email:
            v?.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
              ?.value ?? "",
          name: makeName(v?.name?.[0] || {}) || "",
          id: v?.patmrn ?? "",
          dis: `${
            (v?.gender + ", " ?? "") +
            (v?.birthDate ? getAge(v?.birthDate) : "")
          }
              ${v?.telecom[0]?.value ? " | " + v?.telecom[0]?.value : ""}`,
          encounter: v?.encounter,
          mobileNumber:
            v?.telecom?.find((tel) => tel?.system === "CodingMaster/10473")
              ?.value ||
            v.telecom?.find((tel) => tel?.system === "CodingMaster/10469")
              ?.value ||
            "",
          patientId: v?._id,
          img:
            getImgUrl(v?.photo?.[0]?.fileid) ||
            "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
        });
      });
      const list = lista?.encounter?.map((val, i) => ({
        encstatus: val?.encstatus,
        encounterId: val?._id,
        encounterName: val?._key ? `EN - ${val?._key}` : "",
        type: val?.type?.shortDesc ?? "",
        speciality: val?.practitioner_details?.speciality?.display ?? "-",
        encounterStart: val?.encounterdate ?? "",
        encounterEnd: val?.encounterenddate ?? "",
        location: val?.location?.[i]?.physicalType ?? "",
        doctorName: val?.practitioner_details?.idname,
      }));
      setIsdisableright(false);
      setencPageCount(encPagecount === 0 ? 0 : encPagecount);
      setEncounterDataList(list);
      setcount(count === 1 ? 1 : count - 1);
    }
  };

  const patientCardDetails = LoadpatientData?.state;

  //const encounterDetails = LoadpatientData?.state?.encounter ?? "";

  // console.log(

  //   "LoadpatientData?.state?.encounter",

  //   `EN - ${LoadpatientData?.state?.encounter[counter]?._key ?? ""}`

  // );

  const GetIdAndDisplay = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];

      var datatemp = {
        value: item.longdesc,

        _id: item._id,
      };

      list.push(datatemp);
    }

    return list;
  };
  const constructData = (encounters) => {
    const list = encounters?.map((val, i) => ({
      encstatus: val?.encstatus,
      encounterId: val?._id,
      encounterName: val?._key ? `EN - ${val?._key}` : "",
      type: val?.type?.shortDesc ?? "",
      speciality: val?.practitioner_details?.speciality?.display ?? "-",
      encounterStart: val?.encounterdate ?? "",
      encounterEnd: val?.encounterenddate ?? "",
      location: val?.location?.[i]?.physicalType ?? "",
      doctorName: val?.practitioner_details?.idname,
    }));
    return list;
  };
  const createDate = (date) => {
    return moment.unix(date).format("YYYY-MM-DD");
  };
  useEffect(() => {
    const constructDataList = constructData(LoadpatientData?.state?.encounter);
    setEncounterDate(createDate(constructDataList?.[0]?.encounterStart));
    setSelectEncounterDataList(constructDataList?.[0]);
    setEncounterDataList(constructDataList);
  }, []);
  const handleDateChange = (name, value) => {
    let updatedData = { ...billNo };
    updatedData[name] = value;
    setBillNo({ ...updatedData });
  };
  useEffect(() => {
    if (encounterDate) {
      getBillNo();
    }
  }, [encounterDate]);
  const handleFinClose = () => {
    getFinanceData(encounterNo);
    setIsFin(false);
  };

  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div style={{ backgroundColor: "#F4F5F8", paddingBottom: "1px" }}>
      {/* ------------------------------------------------------ Top Layer Cards ----------------------------------------------------- */}

      <Row
        id="patient_detail_parent_row"
        inLineStyles={{
          // background:

          // 	"transparent linear-gradient(90deg, rgb(251 194 235 / 20%) 0%, hsl(237deg 78% 82% / 20%) 49%, #6bbbff33 100%) 0% 0% no-repeat padding-box",

          while: "white",

          padding: "5px 10px 5px 10px",

          borderBottom: "1px solid #DEE5EC",

          backgroundColor: "#fff",
        }}
      >
        <Col
          id="patient_detail_parent_col"
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          inLineStyles={{ padding: 5, borderRight: "1px solid #DFE5EB" }}
        >
          <PatientInfoCard
            parent_id={"patient_detail_billing"}
            pic={patientCardDetails?.img ?? cardIcon}
            name={patientCardDetails?.name ?? "--------------"}
            age={patientCardDetails?.age ?? "-"}
            gender={patientCardDetails?.gender ?? "-"}
            mrn_id={patientCardDetails?.id ?? "MRN_-----"}
            pi_id={patientCardDetails?.idnumber ?? "PI------"}
            patientphonenum={patientCardDetails?.mobileNumber ?? "------"}
            walletData={outPlusWallte?.advanceamount}
          />
        </Col>

        <Col
          id="patient_detail_statuscard_col"
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          inLineStyles={{ padding: 5, borderRight: "1px solid #DFE5EB" }}
        >
          {/* <EncounterCard
            encounterDetails={encounterDetails}
            counter={counter}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            parent_id={"patient_detail"}
            pic={
              cardIcon

              // "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
            }
            titles={[
              encounterData?.id ?? "65892",

              "OP",

              encounterData?.type ?? "type",

              encounterData?.speciality ?? "Cardiology",
            ]}
            date={
              encounterData
                ? utcTOLocal(encounterData?.encounterdate, "DD MMM YYYY")
                : "01 Jan 22 10:10 - 01 Jan 22 23:00"
            }
            languages={encounterData?.lang ?? "lang"}
          /> */}
          <EncounterListCard
            parent_id={"patient_detail"}
            encounterList={encounterDataList}
            selected={selectencounterDataList}
            handleEncounterSelect={handleEncounterSelect}
            getPreviousSet={getPreviousSet}
            getNextSet={getNextSet}
            Isdisableright={Isdisableright}
            Isdisableleft={Isdisableleft}
            count={count}
          />
        </Col>

        <Col
          id="patient_detail_care_typecard_col"
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          inLineStyles={{ padding: 5 }}
        >
          <PatientCareTypeCard
            parent_id={"patient_detail"}
            careList={finData}
            selected={selectOption}
            onChange={(data) => changeState("selectOption", data)}
            isDetailed={true}
            Update={() => setIsFin(true)}
            activeStep={activeStep}
          />
        </Col>
      </Row>
      {/* ---------------------------------------------------------------------------------------------------------------------------- */}

      <div className={classes.contents}>
        <Grid
          container
          style={{
            borderBottom: "1px solid #DFE5EB",

            paddingRight: "20px",

            alignItems: "center",
          }}
        >
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={2} style={{ padding: "10px 10px 10px 22px" }}>
                <Typography className={classes.header}>Billing</Typography>
              </Grid>

              <Grid item xs={10}>
                <Stepper
                  className={classes.stepper}
                  activeStep={activeStep}
                  style={{ padding: "10px 0px 10px 8px" }}
                >
                  {steps.map((val, i) => (
                    <Step key={i}>
                      <StepLabel>{val}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container>
              {activeStep === 0 ? (
                <>
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <FormControlLabel
                      style={{ margin: "0px 16px 0px 0px" }}
                      control={
                        <Checkbox
                          checked={unBilledCheckBox}
                          color="primary"
                          size="small"
                          onChange={(e) => handleUnBilled(e)}
                        />
                      }
                      label={
                        <Typography className={classes.labelStyle}>
                          Unbilled charges Only?
                        </Typography>
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Div
                      id="sidemenu_search_input_sub_div"
                      style={{ display: "flex", position: "relative" }}
                    >
                      <TextInput
                        id="sidemenu_search_input_textinput"
                        value={codeSearch}
                        onChange={(evt) => {
                          handleCodeSearch(evt.target.value);
                        }}
                        style={{
                          margin: "6px 16px 0px 0px",

                          borderRadius: 8,

                          borderColor: UIColor.lineBorderFill.color,

                          padding: "0px 10px",

                          height: 30,
                        }}
                        // search

                        variant={"outlined"}
                        // id={"inputId"}

                        label=""
                        placeholder="Search Code & Description"
                        hoverColor="#0071F2"
                      />

                      <SearchIcon
                        id="sidemenu_search_searchicon"
                        className={classes.searchIcon}
                      />
                    </Div>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} className={classes.addNewColumn}>
                  <Button
                    onClick={handleAddnew}
                    variant="contained"
                    style={{
                      backgroundColor: "#EC6A49",

                      textTransform: "none",

                      color: "#fff",

                      fontSize: "12px",
                    }}
                  >
                    + Add New
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        {activeStep !== 0 && (
          <Grid
            container
            spacing={2}
            style={{
              padding: "8px 20px 0px",
            }}
          >
            {/* <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  showArrow
                  label="Payer"
                  options={GetIdAndDisplay(insurancePayer?.data) ?? []}
                  value={payer?.value ?? ""}
                  onChangeValue={(e) => handlePayer(e)}
                  placeholder="select..."
                  labelStyle={styles.labelStyle}
                  inLineStyles={{ ...styles.borderStyle, width: "100%" }} // Set width to 100%
                  hoverColor={UIColor.primaryColor.color}
                />
              </Grid>
              
            </Grid>
        </Grid> */}

            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextInput
                    className={"textInput"}
                    id={"bill"}
                    label={"Bill No"}
                    placeholder=""
                    labelStyle={styles.labelStyle}
                    style={{ ...styles.borderStyle, ...styles.inputStyle }}
                    value={billNo?.billNum ?? ""}
                    hoverColor={UIColor.lineBorderFill.color}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextInput
                    className={"textInput"}
                    id={"date"}
                    label={"Date"}
                    type="date"
                    onChange={(e) => handleDateChange("date", e.target.value)}
                    placeholder=""
                    labelStyle={styles.labelStyle}
                    style={{ ...styles.borderStyle, ...styles.inputStyle }}
                    // value={billNo?.date}
                    value={encounterDate}
                    hoverColor={UIColor.lineBorderFill.color}
                    max={currentDate}
                    // disabled={true}
                  />
                </Grid>

                {/* {activeStep === 1 && (<Grid item xs={3}>
              <TextInput
                className={"textInput"}
                id={"receipt"}
                label={"Receipt No"}
                placeholder=""
                labelStyle={styles.labelStyle}
                style={{ ...styles.borderStyle, ...styles.inputStyle }}
                value={receipt?.receiptNo}
                hoverColor={UIColor.lineBorderFill.color}
                disabled={true}
              />
            </Grid>)}
            {activeStep === 1 && (<Grid item xs={3}>
              <TextInput
                className={"textInput"}
                id={"reciept_date"}
                label={"Date"}
                placeholder=""
                labelStyle={styles.labelStyle}
                style={{ ...styles.borderStyle, ...styles.inputStyle }}
                value={receipt?.date}
                hoverColor={UIColor.lineBorderFill.color}
                disabled={true}
              />
            </Grid>)} */}
              </Grid>
            </Grid>
          </Grid>
        )}
        <div style={{ overflowY: "auto", height: "87%", overflowX: "hidden" }}>
          {/*----------------------------------- Loading the Tables----------------------------------- */}

          {getStepperComponents(activeStep)}
          {finData.length > 1 && (
            <Popover
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={openSplitPayer}
              anchorEl={popoverAnchorEl}
              onClose={() => setOpenSplitPayer(false)}
            >
              <SplitCashPopup
                setOpenSplitPayer={setOpenSplitPayer}
                handlePayerSplitUpdate={handlePayerSplitUpdate}
                payerSplitData={payerSplitData}
                payerSplitIndex={payerSplitIndex}
                finData={finData}
              />
            </Popover>
          )}

          {/* ---------------------------------------------------------------------------------------------- */}
        </div>
      </div>
      <div
        className={classes.buttonAlign}
        style={{ justifyContent: activeStep !== 0 ? "space-between" : "end" }}
      >
        {activeStep !== 0 && (
          <>
            <Typography className={classes.calculationLabel}>
              Remarks :
            </Typography>

            <TextField
              id="standard-full-width"
              fullWidth
              margin="normal"
              onChange={(e) => handlePayementDetails(e.target.value, "remarks")}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              className={classes.remarksTextField}
              inputProps={{ maxLength: 200 }}
              value={props?.paymentDetails?.remarks}
              size={"small"}
              variant={"outlined"}
            />
          </>
        )}
        <div style={{ display: "flex", gap: 10 }}>
          <Button
            style={{ color: "#6A7888" }}
            onClick={handleCancel}
            className={classes.forButton}
          >
            Cancel
          </Button>

          {activeStep !== 0 && (
            <>
              <Button
                variant="contained"
                style={{ backgroundColor: "#DEE5EC", color: "#2A3C50" }}
                onClick={handlePrevious}
                className={classes.forButton}
              >
                Previous
              </Button>
            </>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={activeStep === 0 ? handleProceed : handleSave}
            className={classes.forButton}
            disabled={loading ? true : false}
          >
            {activeStep === 0
              ? "Proceed for billing"
              : loading
              ? "Saving..."
              : "Save"}
          </Button>
        </div>
      </div>
      {alertData.open && (
        <AlertsRestriction {...alertData} handleClose={closeAlertModal} />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        open={isFin}
        onClose={() => setIsFin(false)}
      >
        <FinanceCRUD
          setIsFin={setIsFin}
          isFin={isFin}
          encounterId={encounterNo}
          selectencounterDataList={selectencounterDataList}
          handleFinClose={handleFinClose}
        />
      </Dialog>
    </div>
  );
};

export default withAllContexts(withAppBar(CashierBillingDetails));
