export const AppRoutes = {
  signIn: "/",
  forgotPassword: "/forgot-password",
  changePassword: "/change-password",
  expired: "/expired",
  resetPassword: "/reset-Password",
  dashboard: "/dashboard",
  patientRegistration: "/patient-registration",
  financeRegistration: "/finance-registration",
  visitRegistration: "/visit-registration",
  workList: "/work-list",
  dayEndProcess: "/day-end-process",
  assemblePatioentRegister: "/assemble-patient-form",
  assembleNextOfKin: "/assemble-nextofkin-form",
  patientVisitDetails: "/visit_registration_form",
  patientReferral: "/referral_registration_form",
  PatientDashBoard: "/patient_dashboard",
  confirmBooking: "/appointment_confirmation",
  patientDetail: "/patient_detail",
  appointmentBooking: "/appointment_booking",
  searchFacility: "/doctor_consultation",
  billingDetails: "/billing_details",
  billingSummary: "/billing_summary",
  clinicalInfo: "/clinical_info",
  referralInRegistration: "/referral_registration",
  treatmentPlan: "/treatment_plan",
  cashCollection: "/cashier_Collection",
  paymentCollections: "/payment_Collections",
  dashboardLanding: "/picdashboard",
  iframes: "/iframes",
  advancePayment: "/advancePayment",
  cashCollectionReport: "/cashCollectionReport",
  observationReport: "/observationReport",
  bulkBooking: "/bulkbooking",
  monthwiseAttendanceReport: "/monthwise_attendance_report",
  picReviewSheet: "/pic_review_sheet",
  patientReviewChartReport:"/patient_review_chart_report",
  panelReports: "/panelReport",
  epoConsumptionReport: "/epoconsumption_report",
  payerMapping: "/payerMapping",
  PatientDetailWithoutAppBar:"/PatientDetail_WoAp",
  patientDashBoardWithoutAppBar:"/patientDashBoard_WoAp"

};
