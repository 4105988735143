import { Div, Col, Divider, Avatar, Text, H6} from "qdm-component-library";
import { useStyles } from "./styles";
import Place from "./../../../assets/img/svg/place.svg"
import { DoctorProfile } from "./appointmentDetails/doctorProfile";
import { Modal } from "@material-ui/core";
import { getImgUrl } from "../../../utils";
import ClinicProfile from "./doctorCardDetail/clinicInfo";
import { useState } from "react";
import actions from "../../../redux/actions";
import { useDispatch } from "react-redux";

const DoctorDetailsCard = (props) => {
  const classes = useStyles();
  const { parent_id } = props;
  const data = JSON.parse(JSON.stringify(props.data));
  const dispatch = useDispatch();
  const [state, setState] = useState({});
  
  const [profileData, setProfileData] = useState({ });
  const changeState = async(key, value,_key,profileValue) => {
    if (key === "openClinicProfile") {
      const data = await dispatch(actions.READ_CLINICINFO({ locationid: _key}));
      setState((prevState) => ({
        ...prevState,
        clinicData: data?.payload?.data?.[0],
        [key]: value,
      }));
    }
    if (profileValue){
      setProfileData(profileValue)
      setState((prevState) => ({
        ...prevState,
        doctorData: data?.payload,
        [key]: value,
      }));
    }
    if (key === "data") {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
        loading: false,
      }));
      // setState({
      //   ...state,
      //   [key]: value,
      //   loading: false,
      // });
    } else {
      setState((prevState) => ({
        ...prevState,
        [key]: value,
      }));
      console.log(state,"state",profileData);

      // setState({
      //   ...state,
      //   [key]: value,
      // });
    }
  };
  console.log('====================================');
  console.log(state);
  console.log('====================================');

  return (
    <Div
      id={`${parent_id}_doctorcard_img_div`}
      key={"0"}
      style={{ width: "100%" }}
    >
      <Col
        id={`${parent_id}_doctorcard_img_col`}
        key={"0"}
        name="qdm-col-12"
        alignItems="stretch"
        direction="row"
        justify="flex-start"
        textAlign="left"
        lg="12"
        xs="12"
        md="12"
        sm="12"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          paddingLeft: 0,
        }}
      >
        <Div
          id={`${parent_id}_doctorcard_img_sub_div`}
          key={"0"}
          inLineStyles=""
          className="qdm"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            marginBottom: 16,
          }}
        >
          <Avatar
            id={`${parent_id}_doctorcard_image`}
            key={"0"}
            variant="circle"
            letter="Avatar"
            src={
              data?.img ||
              "https://worthingtonmotorcycles.com.au/wp-content/uploads/2017/06/tlc-perth-user-icon-im2.png"
            }
            alt="Image"
            style={{
              margin: "0px",
              width: 40,
              height: 40,
              backgroundColor: "#6F6F6F",
            }}
          ></Avatar>

          <Div
            id={`${parent_id}_doctorcard_title_div`}
            key={"1"}
            style={{
              marginLeft: 16,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              id={`${parent_id}_${data?.name}_doctorcard_text`}
              style={{
                fontSize: 16,
                color: "#001C3C",
                letterSpacing: 0,
              }}
              key={"0"}
              name={data?.name}
              className={"pc_medium"}
            ></Text>
            {/* <br /> */}
            {data?.subtext && (
              <Text
                id={`${parent_id}_${data?.subtex}_doctorcard_text`}
                key={"1"}
                name={data?.subtext}
                style={{
                  fontSize: "12px",
                  marginTop: 4,
                  color: data.isDoctor ? "#001C3C" : "#6f6f6f",
                }}
                className={"pc_regular"}
              ></Text>
            )}
            {/* <br /> */}
            {data?.title && (
              <Text
                id={`${parent_id}_${data?.title}_doctorcard_text`}
                key={"2"}
                name={data?.title}
                style={{
                  fontSize: "12px",
                  color: "#6F6F6F",
                  marginTop: "4px",
                }}
              ></Text>
            )}
          </Div>
        </Div>

        <Div
          id={`${parent_id}_${
            (data?.price ? "₹ " : "") + data?.price
          }_doctorcard_div`}
          key={"1"}
        >
          <Text
            id={`${parent_id}_${
              (data?.price ? "₹ " : "") + data?.price
            }_doctorcard_text`}
            style={{ fontSize: 16, fontWeight: "800" }}
            key={"0"}
            name={(data?.price ? "₹ " : "") + data?.price}
          ></Text>
        </Div>
      </Col>
      <div
        id={`${parent_id}_doctorcard_divider_div`}
        key={"1"}
        style={{
          padding: "0px",
          margin: 0,
          height: 1,
          width: "100%",
        }}
      >
        <Divider
          id={`${parent_id}_doctorcard_divider`}
          key={"0"}
          className={classes.hrTag}
        ></Divider>
      </div>
      {data?.isDoctor && (
        <Col
          id={`${parent_id}_doctorcard_language_col`}
          key={"2"}
          style={{ padding: "16px 0px" }}
        >
          <Text
            id={`${parent_id}_doctorcard_language_text`}
            key={"0"}
            name="Languages :"
            style={{
              color: "#6A7888",
              marginRight: "8px",
              fontSize: 14,
              letterSpacing: ".9px",
            }}
            className={"pc_regular"}
          ></Text>

          <Text
            id={`${parent_id}_doctorcard_${data?.language}_language_text`}
            key={"1"}
            style={{
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: ".9px",
              color: "#6A7888",
            }}
            name={data?.language?.join(", ")}
            className={"pc_regular"}
          ></Text>
        </Col>
      )}
      {data?.isClinic && (
        <Col
          id={`${parent_id}_doctorcard_clinic_col`}
          key={"3"}
          name="qdm-col-12"
          alignItems="stretch"
          direction="row"
          justify="flex-start"
          textAlign="left"
          lg="12"
          xs="12"
          md="12"
          sm="12"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 0px",
          }}
        >
          <Div id={`${parent_id}_doctorcard_clinic_doctor_div`} key={"0"}>
            <Text
              id={`${parent_id}_doctorcard_clinic_doctor_text`}
              key={"0"}
              name={`Includes ${data?.memberIncludes || 0} Doctors`}
              // name={"Includes 6 Doctors"}
              style={{ fontSize: "16px", letterSpacing: 0 }}
              className={"pc_medium"}
            ></Text>
          </Div>

          <Div
            id={`${parent_id}_doctorcard_clinic_image_div`}
            key={"1"}
            className="qdm"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "",
            }}
          >
            <Avatar
              id={`${parent_id}_doctorcard_clinic_image`}
              key={"0"}
              variant="circle"
              letter="Avatar"
              src={
                data?.memberIncludesImg?.find((img) => !!img) ||
                "https://worthingtonmotorcycles.com.au/wp-content/uploads/2017/06/tlc-perth-user-icon-im2.png"
              }
              alt="Image"
              style={{
                margin: "0px",
                marginRight: "0px",
                width: 40,
                height: 40,
              }}
            ></Avatar>

            <Text
              id={`${parent_id}_doctorcard_clinic_text`}
              key={"1"}
              name={
                data?.memberIncludes > 1
                  ? `+ ${data?.memberIncludes - 1 || 0}`
                  : ""
              }
              style={{
                marginLeft: "10px",
                fontSize: "14px",
                fontWeight: "600",
              }}
            ></Text>
          </Div>
        </Col>
      )}
      <Col
        id={`${parent_id}_doctorcard_clinic_col`}
        key={"4"}
        name="qdm-col-12"
        alignItems="stretch"
        direction="row"
        justify="flex-start"
        textAlign="left"
        lg="12"
        xs="12"
        md="12"
        sm="12"
        style={{ padding: "0px" }}
      >
        <Divider
          id={`${parent_id}_doctorcard_clinic_bottom_divider`}
          key={"0"}
          className={classes.hrTag}
        ></Divider>
      </Col>
      <Col
        id={`${parent_id}_doctorcard_hospitalname_col`}
        key={"5"}
        name="qdm-col-12"
        alignItems="stretch"
        direction="row"
        justify="flex-start"
        textAlign="left"
        lg="12"
        xs="12"
        md="12"
        sm="12"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 0,
          marginTop: 16,
        }}
      >
        <Col
          id={`${parent_id}_doctorcard_hospitalname_col_one`}
          key={"0"}
          lg="9"
          xs="9"
          md="9"
          sm="9"
          style={{ padding: 0 }}
        >
          <Text
            id={`${parent_id}_doctorcard_${data?.hospitalName}_text`}
            key={"0"}
            name={data?.hospitalName}
            style={{
              fontSize: "14px",
              letterSpacing: 0,
              color: "#2A3C50",
            }}
            className={"pc_medium"}
          ></Text>

          <H6
            id={`${parent_id}_doctorcard_${data?.hospitalLocation}_h6`}
            key={"1"}
            name={data?.hospitalLocation}
            style={{
              color: "#2A3C50",
              fontSize: "12px",
              letterSpacing: 0,
            }}
            className={"pc_medium"}
          ></H6>
        </Col>

        <Col
          id={`${parent_id}_doctorcard_hospital_km_col`}
          lg="3"
          xs="3"
          md="3"
          sm="3"
          key={"1"}
          className="qdm"
          inLineStyles=""
          style={{
            // borderColor: "#E0E0E0",
            // borderWidth: "1px",
            // borderStyle: "solid",
            borderRadius: "6px",
            padding: "6px 8px",
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#ECF0F7",
            color: "#2A3C50",
          }}
        >
          <img
            id={`${parent_id}_doctorcard_hospital_place_img`}
            alt="Place"
            src={Place}
          />

          <Text
            id={`${parent_id}_doctorcard_hospital_${
              data?.kms ? `${data?.kms} Kms` : ""
            }_text`}
            key={"1"}
            name={data?.kms ? `${data?.kms} Kms` : ""}
            style={{ color: "", marginLeft: "6px", fontSize: "10px" }}
            className={"pc_semibold"}
          ></Text>
        </Col>
      </Col>
      <Div
        id={`${parent_id}_doctorcard_hospital_div`}
        style={{ width: "100%", position: "relative" }}
      ></Div>

      <Col
        id={`${parent_id}_doctorcard_view_clinic_col`}
        key={"6"}
        name="qdm-col-12"
        alignItems="stretch"
        direction="row"
        justify="flex-start"
        textAlign="left"
        lg="12"
        xs="12"
        md="12"
        sm="12"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: 0,
          position: "absolute",
          bottom: 0,
        }}
      >
        {data?.isDoctor && (
          <Col
            id={`${parent_id}_doctorcard_view_doctor_col`}
            key={"2"}
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Text
              id={`${parent_id}_doctorcard_view_doctor_text`}
              key={"1"}
              style={{
                fontSize: 14,
                cursor: "pointer",
                letterSpacing: ".9px",
                color: "#2A60BC",
                fontWeight: 500,
              }}
              name={"View Doctor Profile"}
              className={"pc_medium"}
              onClick={() => changeState("openDoctorProfile", true,"",data)}
            ></Text>
          </Col>
        )}
        {data?.isClinic && (
          <Col
            id={`${parent_id}_doctorcard_view_clinix_col`}
            key={"2"}
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Text
              id={`${parent_id}_doctorcard_view_clinix_text`}
              key={"1"}
              style={{
                fontSize: 14,
                cursor: "pointer",
                letterSpacing: ".9px",
                color: "#0071F2",
              }}
              onClick={() =>
                changeState("openClinicProfile", true, data?.locationId)
              }
              name={"View Clinic Profile"}
              className={"pc_medium"}
            ></Text>
          </Col>
        )}
      </Col>
      <Modal
  id={`${parent_id}_doctorcard_modal`}
  open={state?.openDoctorProfile}
  onClose={() => changeState("openDoctorProfile", false)}
  width={300}
  style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      position: 'relative',
      background: 'white',
      borderRadius: '8px',
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      maxWidth: '40%',
      width: 'auto', 
    }}
  >
    <button
      onClick={() => changeState("openDoctorProfile", false)}
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'transparent',
        border: 'none',
        fontSize: '16px',
        cursor: 'pointer',
        color: '#888',
      }}
    >
      &times;
    </button>

    {data?.isDoctor && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <DoctorProfile
          parent_id={"doctor_card_detail"}
          name={profileData?.name}
          specialized="General Physician"
          courses={profileData?.subtext}
          speciality={profileData?.specialities ?? []}
          experience={profileData?.experience || "-----"}
          languages={(
            profileData?.language && profileData?.language?.map((_) => _)?.join(", ")
          ) || "---"}
          role={profileData?.role || "----"}
          hospitalName={profileData?.hospitalName}
          hospitalLocation={profileData?.hospitalLocation}
          mobile={profileData?.mobile}
          profilePic={
            profileData?.img ||
            "https://worthingtonmotorcycles.com.au/wp-content/uploads/2017/06/tlc-perth-user-icon-im2.png"
          }
          bio={profileData?.bio}
        />
      </div>
    )}
  </div>
</Modal>




          <Modal
          id={`${parent_id}_${
            "_" + data?.name.replaceAll(" ", "_")
          }_clinic_card_detail_right_card_modal`}
          open={state?.openClinicProfile}
          onClose={() =>changeState("openClinicProfile", false)}
          width={600}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
           <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      position: 'relative',
      background: 'white',
      borderRadius: '8px',
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      maxWidth: '100%',
      width: 'auto', 
    }}
  >
    <button
      onClick={() => changeState("openClinicProfile", false)}
      style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'transparent',
        border: 'none',
        fontSize: '16px',
        cursor: 'pointer',
        color: '#888',
      }}
    >
      &times;
    </button>
          {data?.isClinic && (
              <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
            <ClinicProfile
              open={state?.openClinicProfile}
              parent_id={"Clinic_card_detail"}
              facilityName={state?.clinicData?.managingOrgID?.[0]?.name}
              imgUrl={getImgUrl(state?.clinicData?.photo?.[0]?.fileid)}
              facilityType={
                state?.clinicData?.managingOrgEntityType?.[0]?.coding?.[0]
                  ?.display
              }
              currentDate={state?.clinicData?.createddate}
              clinicName={state?.clinicData?.shortdesc}
              speciality={state?.clinicData?.specialty?.[0]?.display}
              description={state?.clinicData?.longdesc}
              timings={state?.clinicData?.workingdayyear}
              overRide={state?.clinicData?.workingdayyearoverride}
              workigDays={state?.clinicData?.workingdayyear}
            />
            </div>
          )}
          </div>
        </Modal>
    </Div>
  );
};

export default DoctorDetailsCard;
