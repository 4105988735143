import React, { useEffect, useState } from "react";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Avatar } from "@material-ui/core";
import clsx from "clsx";
import {
  Div,
  Text,
  TapSelect,
  Row,
  Divider,
  Button,
  SideMenu,
} from "qdm-component-library";
import {
  DirectConsultation,
  Videoconsultation,
} from "../../../../../components/svg";
import doctorandappointmentslot from "../../../../../assets/img/svg/doctorandappointmentslot.svg";
import { convertTime, utcTOLocal } from "../../../../../utils";
import { UIColor } from "../../../../../themes/theme";
import moment from "moment";
import DeleteIcons from "../../../../../assets/img/svg/deleteIcons.svg";
import { makeStyles } from "@material-ui/core";
import { useStyles } from "../style";
import BookMultipleAppointmentsDrawer from "./bookmultipleAppointmentsDrawer/bookmultipleAppointmentsDrawer";
import BookMultipleAppointmentsLists from "./bookMultipleAppointmentsLists/bookMultipleAppointmentsLists";

function DoctorAndAppointmentSlot(props) {
  console.log("propsofdoctorappoinmentslot", props);
  const classes = useStyles();

  const [state, setState] = useState({
    expanded: false,
    bookAppoinment: false,
    showHide: 0,
    info: JSON.parse(JSON.stringify(props?.multiApppointmentInfo.info)),
  });

  useEffect(() => {
    console.log("check1", props?.multiApppointmentInfo);
    console.log(
      typeof props?.multiApppointmentInfo?.info?.appointments?.startsOn?.date
    );
    if (
      typeof props?.multiApppointmentInfo?.info?.appointments?.startsOn
        ?.date !== "undefined"
    ) {
      console.count("check2");
      console.log("check2", props?.multiApppointmentInfo);

      setState((prevState) => ({
        ...prevState,
        info: JSON.parse(
          JSON.stringify(props?.multiApppointmentInfo?.info ?? {})
        ),
      }));
    }
  }, [props?.multiApppointmentInfo?.info?.appointments?.startsOn?.date]);

  const handleExpandClick = () => {
    setState({
      ...state,
      expanded: !state.expanded,
    });
  };

  const changeState = (key, val) => {
    if (key === "bookAppoinment" && !val) {
      setState((prevState) => ({
        ...prevState,
        [key]: val,
        showHide: 0,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        [key]: val,
      }));
    }
  };
  console.log("doacppslot", state);
  const { parent_id } = props;
  console.log("multiApppoiinfo", props.multiApppointmentInfo.info);
  const oldDate = utcTOLocal(
    props.oldSlotInfo?.info?.dateStart,
    "hh:mm a, Do MMM YYYY"
  );
  return (
    <Card
      id={`${parent_id}_appointmentslot_parent_card`}
      style={{
        boxShadow: "none",
        border: "1.5px solid #E0E0E0",
        borderRadius: 8,
      }}
    >
      <CardActions
        id={`${parent_id}_appointmentslot_cardactions`}
        onClick={handleExpandClick}
        disableSpacing
        style={{
          padding: 0,
          paddingLeft: 16,
          paddingBottom: 0,
          cursor: "pointer",
        }}
      >
        <img
          id={`${parent_id}_appointmentslot_img`}
          alt="doctorandappointmentslot"
          src={doctorandappointmentslot}
          style={{ marginRight: 12 }}
        />
        <Text
          id={`${parent_id}_appointmentslot_Doctor_Slot_text`}
          key={"0"}
          name="Doctor and Appointment Slot"
          style={{ fontSize: "16px", color: "#101010", letterSpacing: 0 }}
          className={"pc_semibold"}
        />

        <IconButton
          id={`${parent_id}_appointmentslot_showmore_iconsbutton`}
          className={clsx(classes.expand, {
            [classes.expandOpen]: props.DoctorAndAppointmentSlotCard,
          })}
          aria-expanded={props.DoctorAndAppointmentSlotCard}
          aria-label="show more"
        >
          <ExpandMoreIcon id={`${parent_id}_appointmentslot_expandmoreicon`} />
        </IconButton>
      </CardActions>
      <Collapse
        id={`${parent_id}_appointmentslot_parent_collapse`}
        className={classes.body}
        in={props.DoctorAndAppointmentSlotCard}
        timeout="auto"
        unmountOnExit
      >
        <Div id={`${parent_id}_appointmentslot_collapse_div`} key={"0"}>
          <Text
            id={`${parent_id}_appointmentslot_consultationmode_text`}
            key={"0"}
            name="Consultation Mode"
            style={{
              fontSize: "14px",
              letterSpacing: "0px",
              color: "#101010",
            }}
            className={"pc_regular"}
          />

          <TapSelect
            id={`${parent_id}_appointmentslot_consultationmode_tapselect`}
            key={"1"}
            label=""
            type="single"
            fullWidth={true}
            textSize="medium"
            iconHeight="14"
            iconWidth="14"
            selectedBorderColor={UIColor.secondaryButtonColor.color}
            selectedBackgroundColor={UIColor.secondaryButtonColor.color}
            selectedTextColor="#fff"
            borderColor={UIColor.differentiationBackground.color}
            backgroundColor={UIColor.differentiationBackground.color}
            textColor={UIColor.secondaryButtonColor.color}
            options={[
              {
                value: process.env.REACT_APP_CODING_APMNT_DIRECT_CONSULT_MODE,
                label: "Direct Consultation",
                disabled: !props?.info?.isdirect,
                icon: <DirectConsultation color={UIColor.tertiaryText.color} />,
              },
              {
                value: process.env.REACT_APP_CODING_APMNT_VIDEO_CONSULT_MODE,
                label: "Video Consultation",
                disabled: !props?.info?.isvideo,
                icon: <Videoconsultation color={UIColor.tertiaryText.color} />,
              },
            ]}
            inLineStyles={{
              // color: "#001C3C",
              // borderColor: "#e0e0e0",
              padding: "6px",
              borderRadius: 8,
              marginTop: 8,
              marginRight: 8,
              // backgroundColor: ""
            }}
            className={classes.TapSelect}
            defaultSelected={props.consultationMode}
            onChange={(val) => {
              props.changeState("consultationMode", val);
            }}
          />

          <Row id={`${parent_id}_appointmentslot_guest_row`} key={"2"}>
            {props.loginType !== "guest" ? (
              <Divider
                id={`${parent_id}_appointmentslot_guest_divider`}
                key={"0"}
                textWidth="50"
                textColor="#000"
                borderColor="#E0E0E0"
                borderWidth="1"
                orientation="horizontal"
                variant="middle"
                style={{ margin: "0px", marginTop: "6px" }}
              />
            ) : (
              ""
            )}

            <Div
              id={`${parent_id}_appointmentslot_guest_div`}
              key={"1"}
              className="qdm"
              style={{
                display: "flex",
                width: "100%",
                marginTop: "16px",
                flexDirection: "column",
              }}
            >
              <Row
                id={`${parent_id}_appointmentslot_newbook_row`}
                key={"0"}
                alignItems="stretch"
                direction="row"
                justify="space-between"
                spacing="2"
                style={{
                  display: props.name ? "flex" : "none",
                  flex: 1,
                }}
              >
                <Div
                  id={`${parent_id}_appointmentslot_userprofile_div`}
                  key={"0"}
                  className="qdm"
                  style={{ display: "flex", flex: 1 }}
                >
                  <Div
                    id={`${parent_id}_appointmentslot_userprofile_sub_div`}
                    key={"0"}
                    style={{ display: "flex" }}
                  >
                    {/* <img
                        id={`${parent_id}_appointmentslot_userprofile_img`}
                        key={"0"}
                        src="https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
                        alt="user profile"
                        className={classes.imgage}
                      /> */}
                    <Avatar src={props.hospitalInfo?.img || undefined}>
                      {props.name
                        ?.replaceAll("Mrs", "")
                        ?.replaceAll("Mr", "")
                        ?.replaceAll(".", "")?.[0] ?? ""}
                    </Avatar>
                  </Div>

                  <Div
                    id={`${parent_id}_appointmentslot_name_div`}
                    key={"1"}
                    className="qdm"
                    style={{
                      display: "flex",
                      marginLeft: "16px",
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    <Div
                      id={`${parent_id}_appointmentslot_name_sub_div`}
                      style={{ lineHeight: 1 }}
                      key={"0"}
                    >
                      <Text
                        id={`${parent_id}_appointmentslot_name_text`}
                        key={"0"}
                        name={props.name}
                        style={{
                          fontSize: "14px",
                          color: "#101010",
                          lineHeight: 1,
                          letterSpacing: 0,
                        }}
                        className={"pc_medium"}
                      />
                    </Div>

                    <Div
                      id={`${parent_id}_appointmentslot_newbook_div`}
                      key={"1"}
                    >
                      <Text
                        id={`${parent_id}_appointmentslot_newbook_text`}
                        key={"0"}
                        name={
                          props.IsModifyUser && !props.newBook
                            ? oldDate
                            : `${props.date}, ${props.time}`
                        }
                        style={{
                          fontSize: 12,
                          textDecoration:
                            props.IsModifyUser &&
                            !props.newBook &&
                            "line-through",
                          color: "#6F6F6F",
                          letterSpacing: 0,
                        }}
                        className={"pc_regular"}
                      />
                    </Div>
                    {props.IsModifyUser && !props.newBook && (
                      <Div
                        id={`${parent_id}_appointmentslot_time_div`}
                        key={"1"}
                      >
                        <Text
                          id={`${parent_id}_appointmentslot_time_text`}
                          key={"0"}
                          name={props.date + ", " + props.time}
                          style={{
                            fontSize: 12,
                            color: "#6F6F6F",
                          }}
                          className={"pc_regular"}
                        />
                      </Div>
                    )}
                  </Div>
                </Div>

                <Div id={`${parent_id}_appointmentslot_remove_div`} key={"1"}>
                  <Button
                    id={`${parent_id}_doctor_appointment_slot_remove_button`}
                    key={"0"}
                    type="button"
                    variant="text"
                    style={{
                      color: UIColor.error.color,
                      fontSize: "10px",
                      fontWeight: 600,
                    }}
                    onClick={() => props.rightSideAllCardfun(false)}
                    className={"pc_regular"}
                  >
                    <img src={DeleteIcons} />
                    {/* //  <deleteIcons style={{fontSize:19}}  /> */}
                  </Button>
                </Div>
              </Row>

              <Div
                id={`${parent_id}_appointmentslot_+Book_Multiple_div`}
                key={"1"}
                className="qdm"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "8px",
                }}
              >
                {!props.is_unregister && (
                  <>
                    {props.loginType !== "guest" && !props.IsModifyUser ? (
                      <Button
                        id={`${parent_id}_appointmentslot_+Book_Multiple_button`}
                        variant="text"
                        key={"0"}
                        name={
                          props.isMultipleAppointment
                            ? `${props.multiApppointmentInfo.data.length} appointments added.`
                            : "+ Book Multiple Appointments"
                        }
                        style={{
                          color: "#2A60BC",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          if (props.isMultipleAppointment) {
                            props.changeState("fromListing", true);
                            setState({
                              ...state,
                              showHide: 1,
                              bookAppoinment: true,
                            });
                            return;
                          }
                          props.handleMultiAppointmentChange(
                            {
                              date: moment(
                                convertTime(
                                  props.time,
                                  "Do MMM YYYY",
                                  "DD-MM-YYYY"
                                ),
                                "DD-MM-YYYY"
                              )
                                .clone()
                                //   .add(1, "day")
                                .format("DD-MM-YYYY"),

                              slotSelect: props.info,
                              milli: moment(props.time, "Do MMM YYYY")
                                .clone()
                                // .add(1, "day")
                                .toDate()
                                .getTime(),
                            },
                            "appointments",
                            "startsOn"
                          );
                          changeState("bookAppoinment", true);
                        }}
                        className={"pc_medium"}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}

                <SideMenu
                  id={`${parent_id}_appointmentslot_Bookappointment_sidemenu`}
                  open={state.bookAppoinment}
                  direction="right"
                  width={650}
                  color="default"
                  onClose={() => changeState("bookAppoinment", false)}
                  style={{ zIndex: 2, padding: 0 }}
                >
                  {state.showHide === 0 && (
                    <BookMultipleAppointmentsDrawer
                      parent_id={"doctor_listing"}
                      multiApppointmentInfo={props.multiApppointmentInfo}
                      handleMultiAppointmentChange={
                        props.handleMultiAppointmentChange
                      }
                      changeState={changeState}
                      previewSchedule={(info) => {
                        if (info) {
                          setState((prevState) => ({
                            ...prevState,
                            info,
                            showHide: 1,
                          }));
                        } else {
                          changeState("showHide", 1);
                        }
                      }}
                      date={props.time}
                      time={{ label: props.date }}
                      info={state.info}
                      hospitalInfo={props.hospitalInfo}
                      //{...props}
                    />
                  )}
                  {state.showHide === 1 && (
                    <BookMultipleAppointmentsLists
                      parent_id={"doctor_listing"}
                      info={state.info}
                      fromListing={props.fromListing}
                      changeState={props.changeState}
                      multiApppointmentInfo={props.multiApppointmentInfo}
                      handleMultiAppointmentChange={
                        props.handleMultiAppointmentChange
                      }
                      onChange={() => changeState("showHide", 0)}
                      confirmAppoinment={() => {
                        props.updateMultiSlots(true);
                        changeState("bookAppoinment", false);
                      }}
                      //{...props}
                    />
                  )}
                </SideMenu>
              </Div>
            </Div>
          </Row>
        </Div>
      </Collapse>
    </Card>
  );
}

export default DoctorAndAppointmentSlot;
