import React, { useEffect, useState, useContext } from "react";
import AppHeader from "../../components/layout/appHeader";
import { Container, Div, Col, Text, Row } from "qdm-component-library";
import withAppBar from "../../hoc/withAppBar";
import withAllContexts from "../../hoc/withAllContexts";
import VitalSigns from "../../screens/patientDetail/vitalSigns";
import { Allergy, AllergyForm } from "../patientDetail/allergyFlows";
import { CheifComplaints } from "../patientDetail/cheifComplaintsFlow";
import { Grid, TextareaAutosize } from "@material-ui/core";
import { TopNavbar } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/actions";
import { AlertContext } from "../../context";
import {
  AlertProps,
  CC_Json_Generator,
  DIAGNOSIS_Json_Generator,
  actionButtonClick,
  getGlobalValueFrom_LclStrg_And_QryStrg,
} from "../../utils";
import jwtDecode from "jwt-decode";
import CheifComplaintsForm from "../patientDetail/cheifComplaintsFlow/cheifComplaintsForm";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../router/routes";
import axios from "axios";
import Diagnosis from "../patientDetail/diagnosisFlow/diagnosis";
import { DiagnonsisForm } from "../patientDetail/diagnosisFlow";
import { UIColor } from "../../themes/theme";
import { Button } from "@material-ui/core";
import {
  axiosCall,
  urls,
  __tenantid__,
  AxiosCall,
  dbName,
  query_ids,
  formatDateFromEpoch,
} from "../../utils";
import CurrentMedication from "../../components/currentMedication";
const styles = {
  vitalsHead: {
    padding: "6px 14px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${UIColor.lineBorderFill.color}`,
    paddind: 10,
  },
  vitailsCard: {
    border: `1px solid ${UIColor.lineBorderFill.color}`,
    borderRadius: 10,
    width: "100%",
    marginBottom: 0,
    background: "#fff",
  },
  labelStyle: {
    color: UIColor.tertiaryText.color,
    fontSize: "12px",
    marginBottom: "6px",
    fontFamily: `pc_regular !important`,
  },
  borderStyle: {
    borderRadius: 8,
    borderColor: UIColor.lineBorderFill.color,
  },
  errorborderStyle: {
    borderRadius: 8,
    borderColor: "red",
  },
  inputStyle: {
    padding: 5,
    marginBottom: 20,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
 },
  inputFiledStyle: {
    borderColor: UIColor.lineBorderFill.color,
    borderRadius: "8px",
  },
  listVitals: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tagsStyle: {
    padding: "4px 12px",
    borderRadius: "50px",
    border: "1px solid #0071F2",
    color: "#0071F2",
    background: "#F5FAFF",
    fontSize: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  list: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  tapSelectStyle: {
    padding: "4px 8px",
    borderColor: UIColor.lineBorderFill.color,
    margin: "4px 10px 6px 0px",
    borderRadius: "8px",
    minWidth: "auto",
    "& span": {
      fontSize: "12px",
    },
  },
  TapSelectcol: {
    display: "flex",
    margin: "auto 0",
    marginBottom: "12px",
    paddingLeft: "4px",
  },
  CalendarStyle: {
    padding: 5,
    marginBottom: 10,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
  
  },
  CalendarStyleIcon: {
    position: "absolute",
    right: "14px",
    bottom: "8px",
    cursor: "pointer",
  },
};

const ClinicalInfo = (props) => {
  const { setSnack } = useContext(AlertContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formComponet, setformComponet] = React.useState({
    open: false,
    diagnosisopen: false,
    diagnosiseditIndex: null,
    id: null,
    type: null,
  });
  const [btnName, setBtnName] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [actionName, setActionName] = useState("");
  const [cancelbookingreason, setCancelbookingreason] = useState({});
  const [allNotes, setAllNotes] = useState([]);
  const [notesTypes, setNotesTypes] = useState([]);
  const [currentMedicationValue, setCurrentMedicationValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editAllergyVal, setAllergyEditVal] = useState({});
  const [editDiagnosisVal, setDiagnosisEditVal] = useState({});

  // * <-------------------EVENTS------------------->
  const userInfo = localStorage?.getItem("UserInfo");
  const decodedUserinfo =
    userInfo === null || userInfo === undefined ? "" : jwtDecode(userInfo);
  const _facility_ = decodedUserinfo?.facilityid ?? "";
  const practitionerid = decodedUserinfo?.practitionerid ?? "";

  const onCancel = () => {
    setformComponet({
      allergyopen: false,
      id: null,
      type: null,
    });
  };
  const loadData = async () => {
    await dispatch(
      actions.ENCOUNTER_BASED_A({
        patient_id:location?.state?.patientId,
      })
     );
  };
  useEffect(() => {
    loadData();
  }, [props]);
  const onEditChart = (id, type,val) => {
    setformComponet({
      open: !formComponet.open,
      id: id,
      type: type,
    });
    setAllergyEditVal(val);
  };
  const onEditChart2 = (id, type, editIndex,val) => {
    setformComponet({
      diagnosisopen: true,
      id: id,
      type: type,
      diagnosiseditIndex: editIndex,
    });
    setDiagnosisEditVal(val);
  };
  const saveAllergy = async (list, editIndex, data) => {
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: true,
      message: editIndex === null ? "Adding New Allergy" : "Updating",
    });

    if (data) {
      let saveFun = await dispatch(actions.SAVE_ALLERGY({ list: data }));

      let allergy = props.allergy;

      if (!saveFun.payload.error) {
        if (!editIndex && editIndex !== 0) {
        } else {
          allergy.list[editIndex] = {
            ...list[0],
            isNew: false,
            isModified: true,
          };
        }
      } else {
        AlertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: saveFun.payload.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
      allergy.setAllergy({
        ...allergy,
      });
    }
  };
  const saveCheifComp = async (list, editIndex) => {
    const info = jwtDecode(
      jwtDecode(localStorage.getItem("Token")).access_token
    );
    const searchPractioner = await dispatch(
      actions.SEARCH_PRACTITIONER(info?.email)
    );
    let propsvalue = {
      patientId: location?.state?.patientId ?? location?.state?.patientId,
      encounter_id: location?.state?.encounterID,
      personId:
        location?.state?.encData?.Patient[0]?.personid ??
        location?.state?.personid,
      appointmentId: location?.state?.encData?.appointmentid,
      encounterId: location?.state?.encData?._key,
      type: location?.state?.encData?.type?.longDesc,
      pId: location?.state?.patientId ?? location?.state?.patientId,
      app_id: location?.state?.encData?.appointmentid,
      isProfileView: false,
    };

    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: true,
      message: !editIndex ? "Adding New Chief Complaints" : "Updating",
    });
    let personDetails = searchPractioner?.payload?.data?.[0];
    let search_Practioner = personDetails?.practitioner?.[0];
    let data = CC_Json_Generator(
      propsvalue,
      list,
      search_Practioner,
      personDetails,
      location?.state
    );
    if (data?.length > 0) {
      await dispatch(actions.SAVE_CHEIF_COMPAILANTS({ list: data }));
      let cheifComplaints = props.cheifComplaints;
      if (!editIndex && editIndex !== 0) {
        let data = await dispatch(
          actions.ENC_BASED_CC({
            encounter_id: location?.state?.encounterID,
          })
        );
        let dataList = JSON.parse(
          JSON.stringify({
            ...{
              isNew: true,
              createdName: list.createdName,
              createdAt: list.createdAt,
            },
            ...data?.payload?.data?.[data.payload?.data?.length - 1 ?? 0],
          })
        );
        cheifComplaints.list.push(dataList);
      } else {
        cheifComplaints.list[editIndex] = {
          ...list[0],
          isNew: false,
          isModified: true,
        };
      }
      cheifComplaints.setCheifComplaints({
        ...cheifComplaints,
      });
    }
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: false,
      message: "",
    });
  };
  // const handleClose = (id) => {
  //   let selectedFormId = [...formsList];
  //   let filterId = selectedFormId.filter((val) => val !== id);
  //   changeChartLayer(filterId);
  // };
  const goBackFn = () => {
    navigate(AppRoutes.referralInRegistration, {
      state: {
        loading: false,
        // patientId: location?.state?.patientId??location?.state?.patientId,
        // encounterID: location?.state?.encounterID,
        IsViewMode: location?.state?.IsViewMode,
        isEditedMode: location?.state?.isEditedMode,
        data: location?.state?.data ?? "",
        referraldetails:
          location?.state?.data?.referraldetails ??
          location?.state?.referraldetails ??
          "",
        patientId:
          location?.state?.data?.patientId ??
          location?.state?.data?._id ??
          location?.state?.patientId ??
          location?.state?.patientId,
        encounterID:
          location?.state?.data?.encounterinfo?._id ??
          location?.state?.encounterID,
        personid:
          location?.state?.data?.patientdetails?.personid ??
          location?.state?.personid,
        totalData: location?.state?.totalData,
        addtionaldetails: location?.state?.addtionaldetails,
        nextofkin: location?.state?.nextofkin,
        patientdetails: location?.state?.patientdetails,
        isModify: location?.state?.isModify,
        userId:
          location?.state?.data?.patientdetails?.personid ??
          location?.state?.personId ??
          location?.state?.personid ??
          "",
        //backMode: !location?.state?.IsViewMode && !location?.state?.isModify ? true : false,
        ReferralTrx_Id: location?.state?.ReferralTrx_Id,
      },
    });
  };
  const successfun = () => {
    navigate(AppRoutes.treatmentPlan, {
      state: {
        loading: false,
        IsViewMode: location?.state?.IsViewMode,
        data: location?.state?.data ?? "",
        referraldetails:
          location?.state?.data?.referraldetails ??
          location?.state?.referraldetails ??
          "",
        patientId:
          location?.state?.data?.patientId ||
          location?.state?.data?._id ||
          location?.state?.patientId ||
          props?.patientId,
        encounterID:
          location?.state?.data?.encounterinfo?._id ??
          location?.state?.encounterID ??
          location?.state?.encounterID,
        personid:
          location?.state?.data?.patientdetails?.personid ??
          location?.state?.personId ??
          location?.state?.personid ??
          "",
        totalData: location?.state?.totalData,
        addtionaldetails: location?.state?.addtionaldetails,
        nextofkin: location?.state?.nextofkin,
        patientdetails: location?.state?.patientdetails,
        isModify: location?.state?.isModify ?? false,
        userId:
          location?.state?.data?.patientdetails?.personid ??
          location?.state?.personId ??
          location?.state?.personid ??
          "",
        encounter_id:
          location?.state?.data?.encounterinfo?._id ??
          location?.state?.encounterID ??
          location?.state?.encounterID,
        ReferralTrx_Id: location?.state?.ReferralTrx_Id,
      },
    });
    setBtnName(false);
  };
  let AVCMeeting = {
    RoomName: "",
    component: {
      key: null,
      props: { children: "Hai" },
      ref: null,
      _owner: null,
      _store: { validated: false },
    },
    counter: undefined,
    direction: "right",
    domain: "",
    dragging: false,
    isLarge: null,
    jwt: "",
    onClose: () => {},
    open: false,
    pos: { top: 250, left: 150 },
    rel: null,
    setMeetingDrawer: async (props) => {},
    user_name: "",
    variant: "temporary",
    widthAdjState: undefined,
  };
  // const goBackFn = () => {
  //   navigate(AppRoutes.patientRegistration);
  // };
  const viewModeNavigateToTreatmentPlan = (patientid) => {
    navigate(AppRoutes.treatmentPlan, {
      state: {
        // patientdetails: patientdetails1,
        // nextofkin: nextOfKind1,
        // addtionaldetails: additionalDetails1,
        // allMasters: allMasters,
        IsViewMode: location?.state?.IsViewMode,
        isEditedMode: location?.state?.isEditedMode,
        data: location?.state?.data ?? "",
        patientId:
          location?.state?.data?.patientId ?? location?.state?.data?._id ?? "",
        encounterID: location?.state?.data?.encounterinfo?._id ?? "",
        personid: location?.state?.data?.patientdetails?.personid ?? "",
        totalData: location?.state?.totalData,
      },
    });
  };
  const gettreatmentdata = async () => {
    const data = await dispatch(
      actions.TRAEMENT_PLAN_READ({
        patientid:
          location?.state?.data?.patientId ?? location?.state?.data?._id,
      })
    );
  };
  const handleSideMenu = () => {
    setIsSideMenuOpen(true);
  };
  const handleSideMenuclose = () => {
    setIsSideMenuOpen(false);
  };

  const handleSelectCancelbooking = (v) => {
    setCancelbookingreason(v);
  };

  const actionsMap = location?.state?.totalData?.action.reduce(
    (map, actionItem) => {
      map[actionItem.action] = {
        actionName: actionItem.action,
        action: actionItem.action,
        apiEndPoint: actionItem.apiendpoint,
        backendCall: actionItem.backEndCall,
      };
      return map;
    },
    {}
  );
  const cancelData = () => {
    if (actionName) {
      let data = {};
      const { action, apiEndPoint, backendCall } = actionsMap[actionName];
      data.action = action;
      data.apiEndPoint = apiEndPoint;
      data.backendCall = backendCall;
      data.ticketId = location?.state?.totalData?.ticketId;
      data.cancelReasonId = cancelbookingreason?._id;
      data.queue =
        location?.state?.totalData?.queueMetaData?.queuename?.queuename ??
        "Referral";

      return data;
    }
  };

  const handlecahngeCancelbooking = async () => {
    const { action, apiEndPoint, backendCall } = actionsMap[actionName];
    let fetchVal = await actionButtonClick(
      action,
      apiEndPoint,
      backendCall,
      location?.state?.totalData?.ticketId,
      cancelbookingreason?._id,
      location?.state?.totalData?.queueMetaData?.queuename?.queuename ??
        "Referral"
    );
    if (!fetchVal.error) {
      setIsSideMenuOpen(false);
      navigate(AppRoutes.dashboard);
    } else if (fetchVal.error) {
      setIsSideMenuOpen(true);
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: fetchVal?.message,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  useEffect(() => {
    gettreatmentdata();
  }, []);
  const successfun2 = async () => {
    const userInfo = localStorage.getItem("UserInfo");
    const decodedUserinfo = jwtDecode(userInfo);
    // if (!patientData?.length > 0) {
    //   props?.alert?.setSnack({
    //     open: true,
    //     severity: AlertProps.severity.error,
    //     msg: "Please save the details",
    //     vertical: AlertProps.vertical.top,
    //     horizontal: AlertProps.horizontal.center,
    //     tone: true,
    //   });
    // } else {
    setBtnName(true);
    let payload = {
      ticketId: location?.state?.totalData?.ticketId ?? "",

      userAction: process.env.REACT_APP_REFERRAL_APPROVE,
      data: {
        ticketId: location?.state?.totalData?.ticketId ?? "",
        practitionerId: decodedUserinfo?.practitionerid,
        practitionerRoleId: decodedUserinfo?.roleid,
      },
    };
    let token = localStorage.getItem("JwtToken");
    let register = await axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      url: process.env.REACT_APP_PERFORMUSERACTION_API,
      data: payload,
    })
      .then((res) => {
        if (res.status === 200 && !res.data.error) {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.success,
            msg: "Approved successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          setBtnName(false);
          navigate(AppRoutes.dashboard);
        } else {
          props?.alert?.setSnack({
            open: true,
            severity: AlertProps.severity.error,
            msg: res.data.message,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
          setBtnName(false);
        }
      })
      .catch((error) => {
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please contact to admin",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        setBtnName(false);
      });
    // }
  };

  const saveDiagnosis = async (list, editIndex) => {
    const info = jwtDecode(
      jwtDecode(localStorage.getItem("Token")).access_token
    );
    const searchPractioner = await dispatch(
      actions.SEARCH_PRACTITIONER(info?.email)
    );
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: true,
      message: editIndex === null ? "Adding New Diagnosis" : "Updating",
    });
    let personDetails = searchPractioner?.payload?.data?.[0];
    let search_Practioner = personDetails?.practitioner?.[0];

    let data = DIAGNOSIS_Json_Generator(
      list,
      search_Practioner,
      personDetails,
      location?.state
    );

      if (data?.length > 0) {
        let saveFun = await dispatch(actions.DIAGNOSIS_SAVE({ list: data }));
        // await this.props.DIAGNOSIS_MASTERS_VALUE({
        //   id: list[0]?.diagnosis?.value,
        // });
        let diagnosis = props.Diagnosis;
        let diaList = JSON.parse(JSON.stringify(diagnosis.list));
        if (!saveFun.payload.error) {
          if (!editIndex && editIndex !== 0) {
            let data = await dispatch(
              actions.DIAGNOSIS_READ({
                // encounter_id: encounterDataaaa?.data?.eId,
                patient_id: location?.state?.patientId,
              })
            );

          let dataList = JSON.parse(
            JSON.stringify({
              ...{
                isNew: true,
                createdName: list[0].createdName,
                createdAt: list[0].createdAt,
                onset: list[0].onset,
              },
              ...data?.payload?.data?.[data.payload?.data?.length - 1 ?? 0],
            })
          );
          //console.log(dataList);
          diaList.push(dataList);
        } else {
          if (diaList[editIndex]) {
            diaList[editIndex].isNew = false;
            diaList[editIndex].isModified = true;
          }
        }
      } else {
        AlertContext.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: saveFun.payload.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
      }
      diagnosis.setDiagnosis({
        ...diagnosis,
        list: diaList,
      });
    }
    props?.backDrop?.setBackDrop({
      ...props?.backDrop,
      open: false,
      message: "",
    });
  };
  const handlecurrentmedicationval = (value) => {
    setCurrentMedicationValue(value);
  };
  const handleSave = async () => {
    const now = Date.now();
    const epochTimeInSeconds = Math.floor(now / 1000);
    let payloadList = [];

    const payload = {
      _id: allNotes?.[0]?._id ?? "",
      tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
      facilityid: _facility_,
      patientid: location?.state?.patientId ?? location?.state?.patientId,
      encounterid: location?.state?.encounterID,
      clicnicaldocdate: epochTimeInSeconds,
      clicnicaldoctype: process.env.REACT_APP_CURRENT_MEDICATION ?? "",
      clicnicaldocdesc: currentMedicationValue,
      practitionerid: practitionerid,
    };
    payloadList.push(payload);

    // notesTypes?.[0].CLINICALNOTETYPE?.Value?.map((v, i) => {
    //   let idvalue = ""
    //   let clicnicaldoctype = ""
    //   let clicnicaldocdesc =""
    //   if(v?.display === "Current Medication")
    //   {

    //     if(allNotes?.[i]?.clicnicaldoctype?._id === "CodingMaster/11799")
    //     {
    //       clicnicaldoctype = v?._id;
    //       clicnicaldocdesc = currentMedicationValue;
    //       idvalue =  allNotes?.[i]?._id
    //     }
    //     else
    //     {
    //       clicnicaldoctype = v?._id;
    //       clicnicaldocdesc = currentMedicationValue;
    //       idvalue =  ""
    //     }
    //   }
    //   else {
    //       clicnicaldoctype = v?._id;
    //       clicnicaldocdesc = "";
    //       idvalue =  allNotes?.[i]?._id ?? ""
    //   }

    //   let payload = {
    //     _id: idvalue ?? "",
    //     tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
    //     facilityid: _facility_,
    //     patientid: location?.state?.patientId ?? location?.state?.patientId,
    //     encounterid: location?.state?.encounterID,
    //     clicnicaldocdate: epochTimeInSeconds,
    //     clicnicaldoctype: clicnicaldoctype,
    //     clicnicaldocdesc: clicnicaldocdesc,
    //     practitionerid: practitionerid,
    //   };
    //   payloadList.push(payload);
    // });

    let jwtObj = { jwtToken: localStorage.getItem("JwtToken") };

    if (payloadList.length > 0) {
      const currentclinicalupsert = await axiosCall(
        payloadList,
        urls.saveClinicalNotes,
        jwtObj
      );
      if (!currentclinicalupsert?.error) {
        getcurrentmedicationval();
        setIsEdit(false);
        const alertmsg = isEdit
          ? " Current Medication Updated successfully!"
          : " Current Medication Saved successfully!";
        setSnack({
          open: true,
          severity: AlertProps.severity.success,
          msg: alertmsg,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      } else {
        setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: currentclinicalupsert?.message,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
      }
    }
  };

  const getcurrentmedicationval = async () => {
    const data = await axiosCall(
      {
        db_name: dbName,
        filter: {
          patientid: location?.state?.patientId ?? location?.state?.patientId,
          encounterid: location?.state?.encounterID,
        },
        queryid: query_ids?.allNotesAgainstPatientidAndEncoid,
      },
      urls?.readQdmQueries
    );
    setAllNotes(data);
    if (data?.length > 0) {
      data.forEach((v, i) => {
        if (
          v?.clicnicaldoctype?._id === process.env.REACT_APP_CURRENT_MEDICATION
        ) {
          setCurrentMedicationValue(v?.clicnicaldocdesc);
        }
      });
      //setCurrentMedicationValue(data?.[0]?.clicnicaldocdesc)
    }
    const notesType = await axiosCall(
      {
        db_name: dbName,
        filter: {
          type: ["CLINICALNOTETYPE"],
          tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
          facilityid: _facility_,
          lang: "en",
        },
        queryid: query_ids?.getProgressNotesType,
      },
      urls?.readQdmQueries
    );
    setNotesTypes(notesType);
  };

  const oneditClick = () => {
    setIsEdit(true);
  };
  const currentcancel = () => {
    setIsEdit(false);
    setCurrentMedicationValue(allNotes?.[0]?.clicnicaldocdesc);
  };

  useEffect(() => {
    getcurrentmedicationval();
  }, []);
  console.log(location?.state, "location?.state");
  return (
   
    <Div style={{position:"fixed", width: "100%", height: "100%"}} > 
      <TopNavbar
        goBack={goBackFn}
        parent_id={"Clinical Information"}
        alertHold={"alertHold"}
        putOnHold={
          //() =>
          ""
          //putOnHold("undefined", props.location?.state?.appointmentId)
        }
        newUser={"props.location?.state?.newUser"}
        successfun={successfun}
        navBarArr={[]}
        title={"Clinical Information"}
        btn={
          location?.state?.IsViewMode
            ? "Continue"
            : btnName
            ? "Registering"
            : "Register & continue"
        }
        isAVC={AVCMeeting?.RoomName}
        isViewMode={location?.state?.IsViewMode}
        navigateToNextScreen={viewModeNavigateToTreatmentPlan}
        approveStatus={successfun2}
        isSideMenuOpen={isSideMenuOpen}
        handleSideMenu={handleSideMenu}
        handleSideMenuclose={handleSideMenuclose}
        setActionName={setActionName}
        actionName={actionName}
        handlecahngeCancelbooking={handlecahngeCancelbooking}
        handleSelectCancelbooking={handleSelectCancelbooking}
        cancelbookingreason={cancelbookingreason}
        totalData={location?.state?.totalData}
        cancelData={cancelData()}
        isFileUpload={true}
      />

      <Div style={{overflowY:"scroll", height:"100vh"}}>
      <Grid
        container
        lg={12}
        spacing={2}
        justifyContent="center"
        alignItems="flex-start"
        style={{
          marginTop: "5px",
          paddingBottom: "90vh",
          backgroundColor: "#f4f5f8",
          
         }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="flex-start"
          style={{
            width: "98%",
            borderRadius: "10px",
            backgroundColor: "#f4f5f8",
           }}
        >
          {/* <Grid item container lg={12} justifyContent="flex-start" style={{}} >
                    <Typography variant="caption" style={{color:"#01205C",fontWeight: 600,fontSize: "16px",marginLeft:"12px"}}>
                        Clinical Info
                    </Typography>
                </Grid> */}
               
          <Grid item lg={12} 
          container
          justifyContent="center"
          alignItems="flex-start" >
            <VitalSigns Encounterdata={location?.state} onRegistration={true} />
          </Grid>
        
          <Grid item lg={12} container
         justifyContent="center"
          alignItems="flex-start">
            {formComponet?.open ? (
              <AllergyForm
                parent_id={"patient_details"}
                onCancel={onCancel}
                saveAllergy={saveAllergy}
                encounterData={location?.state}
                editAllergyVal={editAllergyVal}
              />
            ) : (
              <Allergy
                saveAllergy={saveAllergy}
                onEditChart={onEditChart}
                handleClose={"props.handleClose"}
                Encounterdata={location?.state}
                onRegistration={true}
              />
            )}
          </Grid>
          
          <Grid item lg={12} container
         justifyContent="center"
          alignItems="flex-start"
          >
            {formComponet?.diagnosisopen ? (
              <DiagnonsisForm
                parent_id={"patient_details"}
                saveDiagnosis={saveDiagnosis}
                onCancel={onCancel}
                encounterData={location?.state}
                editDiagnosisVal={editDiagnosisVal}
              />
            ) : (
              <Diagnosis
                parent_id={"patientchart"}
                saveDiagnosis={saveDiagnosis}
                onEditChart={onEditChart2}
                handleClose={"props.handleClose"}
                Encounterdata={location?.state}
                onRegistration={true}
              />
            )}
          </Grid>
          
          <Grid item lg={12} 
          container justifyContent="flex-start" 
           alignItems="flex-start" >
          
          <CurrentMedication 
            isEdit={isEdit}
            oneditClick={oneditClick}
            handleSave={handleSave}
            currentcancel={currentcancel}
            handlecurrentmedicationval={handlecurrentmedicationval}
            currentMedicationValue={currentMedicationValue}
            allNotes={allNotes}
            Encounterdata={location?.state}
          />
         
          </Grid>
          
          {/* <Grid item lg={12}>
            {formComponet?.ccopen ? (
              <CheifComplaintsForm
                parent_id={"patient_details"}
                editIndex={formComponet?.cceditIndex}
                saveCheifComp={saveCheifComp}
                onCancel={onCancel}
                formComponet={formComponet}
                encId={location?.state?.encounterID}
                Encounterdata={location?.state}
              />
            ) : (
              <CheifComplaints
                parent_id={"patientchart"}
                saveCheifComp={saveCheifComp}
                onEditChart={onEditChart2}
                handleClose={"props.handleClose"}
                Encounterdata={location?.state}
                onRegistration={true}
              />
            )}
          </Grid> */}
        </Grid>
      </Grid>
      </Div>
   </Div>
  );
};
export default withAllContexts(withAppBar(ClinicalInfo));
