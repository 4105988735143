import React from "react";
import { Div, Row, Col, H6, Text, Icons, Image } from "qdm-component-library";
import { Card } from "@material-ui/core";

export const DoctorProfile = (props) => {
  const { parent_id } = props;

  const styles = {
    colPadding: {
      padding: 7,
    },
    wrapper: {
      padding: "0px 20px 20px 20px",
      overflow: "auto",
    },
    title: {
      fontSize: 16,
      color: "#36A2EB",
      margin: 0,
      marginBottom: 5,
    },
    h6: {
      fontSize: 12,
      color: "#36A2EB",
      margin: 0,
      textTransform: "uppercase",
    },
    h5: {
      fontSize: 12,
      color: "#6F6F6F",
      display: "block",
      margin: 0,
      marginBottom: 10,
    },
    blackSpan: {
      color: "#101010",
      fontSize: 10,
    },
    span: {
      color: "#6F6F6F",
      fontSize: 10,
    },
    bioSpan: {
      color: "#6F6F6F",
      fontSize: 10,
      wordWrap: "break-word",
    },
    content: {
      marginBottom: 20,
    },
    specialityWrap: {
      border: "0.5px solid #E0E0E0",
      borderRadius: 8,
      padding: 5,
      display: "flex",
    },
    flexCenter: {
      display: "flex",
      alignItems: "center",
    },
    icons: {
      marginRight: 10,
      color: "#36A2EB",
    },
    docImage: {
      height: "100%",
      minHeight: "260px",
      borderRadius: "0px 0px 8px 8px",
      boxShadow: "0px 1px 10px #00000029",
      overflow: "hidden",
    },
  };

  return (
    <div id={`${parent_id}_doctor_profile_parent_div`} style={styles.wrapper}>
      <Card>
        <Row id={`${parent_id}_doctor_profile_parent_row`}>
          <Col
            id={`${parent_id}_doctor_profile_parent_col`}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            inLineStyles={{ ...styles.colPadding, paddingTop: 20 }}
          >
            <Div
              id={`${parent_id}_doctor_profile_content_div`}
              style={styles.content}
            >
              {props?.name && (
                <H6
                  id={`${parent_id}_doctor_profile_name_h6`}
                  className="pc_semibold"
                  style={styles.title}
                >
                  {props?.name}
                </H6>
              )}
              {/* {props?.specialized && <H6
                            id={`${parent_id}_doctor_profile_specialized_h6`}
                            className="pc_regular" style={styles.h5}>{props?.specialized}</H6>} */}
              {props?.courses && (
                <H6
                  id={`${parent_id}_doctor_profile_courses_h6`}
                  className="pc_regular"
                  style={styles.span}
                >
                  {props?.courses}
                </H6>
              )}
            </Div>
            {props?.speciality && props?.speciality?.length > 0 && (
              <Div
                id={`${parent_id}_doctor_profile_speciality_div`}
                style={styles.content}
              >
                <H6
                  id={`${parent_id}_doctor_profile_speciality_label_h6`}
                  className="pc_semibold"
                  style={{ ...styles.h6, marginBottom: 5 }}
                >
                  {"Specialty"}
                </H6>
                <Div
                  id={`${parent_id}_doctor_profile_speciality_map_div`}
                  style={styles.flexCenter}
                >
                  {props?.speciality?.map((_, i) => {
                    return (
                      <Div
                        id={`${parent_id}_doctor_profile_speciality_sub_div`}
                        key={i}
                        style={{
                          ...styles.specialityWrap,
                          marginRight:
                            i < props?.speciality?.length - 1 ? 7 : 0,
                        }}
                      >
                        <Text
                          id={`${parent_id}_doctor_profile_speciality_blackspan`}
                          className="pc_regular"
                          style={styles.blackSpan}
                        >
                          {_?.label}
                        </Text>
                      </Div>
                    );
                  })}
                </Div>
              </Div>
            )}
            <Div id={`${parent_id}_doctor_profile_speciality_subitiles_div`}>
              <Div
                id={`${parent_id}_doctor_profile_speciality_experience_label_div`}
                style={{ ...styles.flexCenter, marginBottom: 5 }}
              >
                {props?.experience && (
                  <H6
                    id={`${parent_id}_doctor_profile_speciality_experience_label_h6`}
                    className="pc_semibold"
                    style={styles.h6}
                  >
                    {"Experience"}
                  </H6>
                )}

                {props?.experience && (
                  <Text
                    id={`${parent_id}_doctor_profile_speciality_experience_text`}
                    className="pc_regular"
                    style={{ ...styles.blackSpan, marginLeft: 10 }}
                  >
                    {props?.experience}
                  </Text>
                )}
              </Div>
              <Div
                id={`${parent_id}_doctor_profile_speciality_languages_label_div`}
                style={{ ...styles.flexCenter, marginBottom: 5 }}
              >
                {props?.languages && (
                  <H6
                    id={`${parent_id}_doctor_profile_speciality_languages_label_h6`}
                    className="pc_semibold"
                    style={styles.h6}
                  >
                    {"Languages"}
                  </H6>
                )}
                {props?.languages && (
                  <Text
                    id={`${parent_id}_doctor_profile_speciality_languages_text`}
                    className="pc_regular"
                    style={{ ...styles.blackSpan, marginLeft: 10 }}
                  >
                    {props?.languages}
                  </Text>
                )}
              </Div>
              <Div
                id={`${parent_id}_doctor_profile_speciality_role_type_div`}
                style={{ ...styles.flexCenter, marginBottom: 5 }}
              >
                {props?.role && (
                  <H6
                    id={`${parent_id}_doctor_profile_speciality_role_type_label`}
                    className="pc_semibold"
                    style={styles.h6}
                  >
                    {"Role Type"}
                  </H6>
                )}
                {props?.role && (
                  <Text
                    id={`${parent_id}_doctor_profile_speciality_role_type_text`}
                    className="pc_regular"
                    style={{ ...styles.blackSpan, marginLeft: 10 }}
                  >
                    {props?.role}
                  </Text>
                )}
              </Div>
            </Div>
          </Col>

          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            id={`${parent_id}_doctor_profile_image_col`}
            inLineStyles={{ ...styles.colPadding, paddingTop: 0 }}
          >
            <Div
              id={`${parent_id}_doctor_profile_image_div`}
              style={styles.docImage}
            >
              <Image
                id={`${parent_id}_doctor_profile_image`}
                src={props?.profilePic}
              />
            </Div>
          </Col>
        </Row>
        <Div
          id={`${parent_id}_doctor_profile_biography_div`}
          style={{ marginTop: 10 }}
        >
          <Row id={`${parent_id}_doctor_profile_biography_row`}>
            <Col
              id={`${parent_id}_doctor_profile_biography_col`}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              inLineStyles={styles.colPadding}
            >
              <Div id={`${parent_id}_doctor_profile_biography_label_div`}>
                <H6
                  id={`${parent_id}_doctor_profile_biography_label_h6`}
                  className="pc_semibold"
                  style={{ ...styles.h6, marginBottom: 5 }}
                >
                  {"Biography"}
                </H6>
                <H6
                  id={`${parent_id}_doctor_profile_biography_label_content_h6`}
                  className="pc_regular"
                  style={styles.bioSpan}
                >
                  {props?.bio || "----"}
                </H6>
              </Div>
            </Col>
            <Col
              id={`${parent_id}_doctor_profile_capitalize_col`}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              inLineStyles={styles.colPadding}
            >
              <Div id={`${parent_id}_doctor_profile_capitalize_div`}>
                {props?.hospitalName && (
                  <H6
                    id={`${parent_id}_doctor_profile_capitalize_label_h6`}
                    className="pc_semibold"
                    style={{
                      ...styles.h6,
                      fontSize: 14,
                      marginBottom: 10,
                      textTransform: "capitalize",
                    }}
                  >
                    {props?.hospitalName}
                  </H6>
                )}
                {props?.hospitalLocation && (
                  <Div
                    id={`${parent_id}_doctor_profile_hospital_location_div`}
                    style={{ ...styles.flexCenter, marginBottom: 10 }}
                  >
                    <Icons
                      id={`${parent_id}_doctor_profile_hospital_location_icon`}
                      style={styles.icons}
                      fontIcon="map-marker"
                    />
                    {props?.hospitalLocation && (
                      <Text
                        id={`${parent_id}_doctor_profile_hospital_location_text`}
                        className="pc_regular"
                        style={styles.span}
                      >
                        {props?.hospitalLocation}
                      </Text>
                    )}
                  </Div>
                )}
                {props?.mobile && (
                  <Div
                    id={`${parent_id}_doctor_profile_mobile_div`}
                    style={styles.flexCenter}
                  >
                    <Icons
                      id={`${parent_id}_doctor_profile_mobile_icon`}
                      style={styles.icons}
                      fontIcon="phone"
                    />
                    {props?.mobile && (
                      <Text
                        id={`${parent_id}_doctor_profile_mobile_label_text`}
                        className="pc_regular"
                        style={styles.span}
                      >
                        {props?.mobile}
                      </Text>
                    )}
                  </Div>
                )}
              </Div>
            </Col>
          </Row>
        </Div>
      </Card>
    </div>
  );
};
