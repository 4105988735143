import React, { useEffect, useState } from "react";
import {
  Div,
  Paper,
  Select,
  TextInput,
  H6,
  Col,
  Row,
  Button,
  SideMenu,
  Divider,
  Text,
  Checkbox,
} from "qdm-component-library";
import "./style.css";
import { useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  Dialog,
  Grid,
  IconButton,
  List,
  TextareaAutosize,
  Typography,
  makeStyles,
} from "@material-ui/core";
import FileViewer from "react-file-viewer";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import {
  AxiosCall,
  ToBase64,
  readFileAsBase64,
  urls,
  AlertProps,
  __tenantid__,
  getGlobalValueFrom_LclStrg_And_QryStrg,
} from "../../../utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import jwtDecode from "jwt-decode";
import axios from "axios";
import { UIColor } from "../../../themes/theme";
import withAllContexts from "../../../hoc/withAllContexts";
import actions from "../../../redux/actions";
import PaymentDetailCard from "./PaymentDetailCard";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundColor: "transparent",
  },
  dialogRoot: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0,0,0,0.9)",
    },

    zIndex: "999999 !important",
  },
  chips: {
    "& .MuiChip-root": {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 8,
      },
    },
  },
  autocompleteBorder: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
        borderRadius: 8,
      },
    },
  },
}));

const CollectionSummary = (props) => {
  let styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      border: "1px solid #E0E0E0",
    },
    containedButton: {
      backgroundColor: UIColor.primaryColor.color,
      borderColor: UIColor.primaryColor.color,
      borderRadius: 8,
      padding: "8px 15px",
      minWidth: 100,
      fontFamily: "pc_medium !important",
      fontSize: 14,
    },
    errorborderStyle: {
      borderRadius: 8,
      borderColor: "#FF0000",
    },
  };
  const dispatch = useDispatch();
  const userInfo = localStorage.getItem("UserInfo");
  const decodedUserinfo = jwtDecode(userInfo);
  const classes = useStyles();
  const [collectInfo, setCollectInfo] = useState();
  const [facilityNameData, setFacilityNameData] = useState([]);
  const [practitionerNameData, setPractitionerNameData] = useState([]);
  const [allMasters, setAllMasters] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [isOnEdit, setIsOnEdit] = useState(false);
  const [advanceAmount, setAdvanceAmount] = useState(0);


  const { paymentOrders } = props;
  const currentDate = new Date().toISOString().split("T")[0];
  
  const getOutstanding = async () => {
    const Outstanding = await dispatch(
      actions.GET_OUTSTANDING_DATA(props?.patientid)
    );
    setAdvanceAmount(Outstanding?.payload?.data?.[0]?.advanceamount ?? 0)
  }
  
  const setData = (name, value, total) => {
    let updatedData = { ...collectInfo };
    if (name === "cash") {
      updatedData[name] = options.find((option) => option.value === "Cash");
      updatedData["credit_card"] = false;
      updatedData["debit_card"] = false;
      updatedData["cheque"] = false;
      updatedData["online_transfer"] = false;
      updatedData["advance"] = false;
    } else if (name === "cheque") {
      updatedData[name] = options.find((option) => option.value === "Cheque");
      updatedData["cash"] = false;
      updatedData["credit_card"] = false;
      updatedData["debit_card"] = false;
      updatedData["online_transfer"] = false;
      updatedData["advance"] = false;
    } else if (name === "credit_card") {
      updatedData[name] = options.find(
        (option) => option.value === "Credit Card"
      );
      updatedData["cash"] = false;
      updatedData["debit_card"] = false;
      updatedData["cheque"] = false;
      updatedData["online_transfer"] = false;
      updatedData["advance"] = false;
    } else if (name === "debit_card") {
      updatedData[name] = options.find(
        (option) => option.value === "Debit Card"
      );
      updatedData["cash"] = false;
      updatedData["credit_card"] = false;
      updatedData["cheque"] = false;
      updatedData["online_transfer"] = false;
      updatedData["advance"] = false;
    } else if (name === "online_transfer") {
      updatedData[name] = options.find(
        (option) => option.value === "Online Transfer"
      );
      updatedData["cash"] = false;
      updatedData["credit_card"] = false;
      updatedData["cheque"] = false;
      updatedData["debit_card"] = false;
      updatedData["advance"] = false;
    } else if (name === "advance") {
      updatedData[name] = options.find((option) => option.value === "Advance");
      updatedData["credit_card"] = false;
      updatedData["debit_card"] = false;
      updatedData["cheque"] = false;
      updatedData["online_transfer"] = false;
      updatedData["cash"] = false;
    } else if (name === "totalPayAmount") {
      const data = props.collectionsData.filter(x => x.id !== collectInfo.id);
      const totColamt = calculateTotalCollection(data);
      if(collectInfo?.advance && (Number.isNaN(value)? 0 : Number(value)) > advanceAmount){
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Insufficient balance in Advance Amount",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
          tone: true,
        });
        return;
      }
      if(value === "" || (value>0 && value <= (props.totCollectAmt - totColamt))){
        updatedData[name] = value;
      }
    } else {
      updatedData[name] = value;
      // if (total) {
      //   updatedData["totalPayAmount"] = total;
      // }
      updatedData["date"] = currentDate;
    }
    setCollectInfo({ ...updatedData });
    // props?.setCashierdatas({ ...updatedData });
  };

  const loadPaymentCollectionsDefaultValues = async () => {
    let payload = {
      screenid: process.env.REACT_APP_PAYMENT_COLLECTIONS_SCREEN_ID,
      tenantid: getGlobalValueFrom_LclStrg_And_QryStrg({localStoragekeyName:"UserInfo",returnObjKeyName:"tenantid"}),
    };
    let token = localStorage.getItem("JwtToken");

    let register = await axios({
      method: "POST",
      url: process.env.REACT_APP_GET_SCREEN_DEFAULT_VALUE,
      headers: {
        "Content-Type": "application/json",
        jwtToken: `${token}`,
      },
      data: JSON.stringify(payload),
    }).then((res) => {
      if (
        res?.data?.response?.screenId ===
        process.env.REACT_APP_PAYMENT_COLLECTIONS_SCREEN_ID
      ) {
        const defaultValues = res?.data?.response?.defaultValues;
        const stateNames = ["instrument_type"];
        const stateValues = {};
        defaultValues.forEach((s) => {
          if (stateNames.includes(s.stateName)) {
            stateValues[s.stateName] = s.value;
          }
        });
        
      const totColamt = calculateTotalCollection(props.collectionsData);
        setCollectInfo({
          totalPayAmount: (props.totCollectAmt - totColamt).toFixed(2),
          date: currentDate,
          payer_Name: props.patientNames,
          online_transfer: {
            value: stateValues?.instrument_type?.value,
            _id: stateValues?.instrument_type?._id,
            label: stateValues?.instrument_type?.value,
          },
          cash: false,
          credit_card: false,
          cheque: false,
          debit_card: false,
          advance: false,
        });
      }
    }).catch((error) => {
      props?.alert?.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please contact to admin",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    });
  };
  const GetIdAndDisplay1 = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        value: item.name,
        _id: item._id,
      };

      list.push(datatemp);
    }
    return list;
  };
  const GetIdAndDisplay = (data) => {
    let list = [];

    for (let index in data) {
      const item = data[index];
      const datatemp = {
        chargecode: item.chargecode,
        value: item.longdesc,
        _id: item._id,
      };

      list.push(datatemp);
    }
    return list;
  };

  const FlterOptions3 = (props, type, istrue, index) => {
    let filteredArray = props.filter((obj) => obj.hasOwnProperty([type]));
    let list = [];
    let data = filteredArray[0]?.[type].Value;
    for (let index in data) {
      var datatemp = {
        // isdisabled:false,
        value: data[index]?.display,
        _id: data[index]?._id,
        label: data[index]?.display,
      };
      list.push(datatemp);
    }
    return list;
  };
  let options = FlterOptions3(allMasters, "RC_PAYMENTMODE");

  const facilityName = async () => {
    const response = await dispatch(
      actions.Facility_Load_Query_DROPDOWN({
        organizationId: decodedUserinfo?.facilityid,
      })
    );
    setFacilityNameData(response?.payload?.data);
  };
  const practitionerName = async () => {
    const response = await dispatch(
      actions.Practitoner_Load_Query_DROPDOWN({
        practitionerId: decodedUserinfo?.practitionerid,
      })
    );
    setPractitionerNameData(response?.payload?.data);
  };
  const getAllMasters = async () => {
    const response = await dispatch(actions.PAYMENT_DROPDOWN());

    setAllMasters(response?.payload?.data);
  };
  const { labelStyle, borderStyle, containedButton, errorborderStyle } = styles;
  const { parent_id } = props;
  useEffect(() => {
    facilityName();
    practitionerName();
    getAllMasters();
    getOutstanding();
    if (!isOnEdit) {
      loadPaymentCollectionsDefaultValues();
    }
  }, []);

  function dateToEpoch(dateString) {
    var date = new Date(dateString);
    var epochTime = date.getTime();
    var epochTimeInSeconds = Math.floor(epochTime / 1000);
    return epochTimeInSeconds;
  }

  useEffect(() => {
    if (downloadUrl) {
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.target = "_blank";
      downloadLink.download = "report.pdf";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [downloadUrl]);

    useEffect(()=>{
      loadPaymentCollectionsDefaultValues();
    },[props.totCollectAmt,props.patientNames]);
  
  const filteredOptions = options.filter((option) => {
    return !(option?.value === "Advance" && props?.isAdvancePayment || option?.value === "Advance" && props?.isRefund);
  });
  const handlefinalsubmit = () => {
      const newArray = props.collectionsData;
      const info = collectInfo;
      const existData = newArray.filter(
        (d) => d?.id !== info?.id 
        && d?.advance?._id === info?.advance?._id
        && d?.cash?._id === info?.cash?._id
        && d?.credit_card?._id === info?.credit_card?._id
        && d?.debit_card?._id === info?.debit_card?._id
        && d?.cheque?._id === info?.cheque?._id
        && d?.online_transfer?._id === info?.online_transfer?._id
      );
      if(collectInfo?.totalPayAmount === "" || collectInfo?.totalPayAmount === 0 ){
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill the Payment Amount",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return false;
      } else if(collectInfo?.advance === false
        && collectInfo?.cash === false && collectInfo?.credit_card === false
        && collectInfo?.debit_card === false && collectInfo?.cheque === false
        && collectInfo?.online_transfer === false){
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please select Instrument Type",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return false;
      } else if(existData.length>0){
        props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "This Instrument Type already selected",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return false;
      } else if(collectInfo?.advance === false
        && collectInfo?.cash === false && (!collectInfo?.instrument_Number || collectInfo?.instrument_Number == "")){
          props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill the Instrument Number",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return false;
      } else if(collectInfo?.advance === false
        && collectInfo?.cash === false && (!collectInfo?.Bank_And_Cash_Account || collectInfo?.Bank_And_Cash_Account == "")){
          props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill the Bank Name",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return false;
      } else if(!collectInfo?.payer_Name || collectInfo?.payer_Name === ""){
          props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill the Payer Name",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return false;
      } else if(collectInfo?.totalPayAmount > advanceAmount && collectInfo?.advance){
          props?.alert?.setSnack({
          open: true,
          severity: AlertProps.severity.error,
          msg: "Insufficient balance in Advance Amount",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.right,
        });
        return false;
      } else if (isOnEdit && typeof collectInfo.id === "number") {
      const colIndex = newArray.findIndex(
        (d) => d?.id === collectInfo?.id
      );
      newArray[colIndex] = collectInfo;
      setIsOnEdit(false);
    } else {
      const newval = collectInfo;
      const ids =  [
        ...new Set(props.collectionsData.map((item) => item.id)),
      ]
      if(ids.length>0){
        newval.id = Math.max(...ids) + 1;
      } else{
        newval.id = 1;
      }
      newArray.push(newval);
    }
      
      props.setCollectionsData(newArray);

    setTimeout(() => {
      const totColamt = calculateTotalCollection(newArray);
      setCollectInfo({
        online_transfer: collectInfo?.online_transfer,
        cash: collectInfo?.cash,
        credit_card: collectInfo?.credit_card,
        cheque: collectInfo?.cheque,
        debit_card: collectInfo?.debit_card,
        advance: collectInfo?.advance,
        payer_Name: props.patientNames,
        totalPayAmount: (props.totCollectAmt - totColamt).toFixed(2),
        instrument_Number: "",
        Bank_And_Cash_Account: "",
        Comments: "",
        date: currentDate,
      });
    }, 500);
  };

  const onDelete = async (v, i) => {
    let colData = props.collectionsData;
    const colIndex = colData.findIndex(
      (d) => d?.id === v?.id
    );
    colData.splice(colIndex, 1);
    props.setCollectionsData(colData);
    let colval = collectInfo;
    const totColamt = calculateTotalCollection(colData);
    colval.totalPayAmount = (props.totCollectAmt - totColamt).toFixed(2);
    setCollectInfo(colval);
    props?.alert?.setSnack({
      open: true,
      severity: AlertProps.severity.success,
      msg: "Deleted successfully",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.right,
    });
  };
  const onEdit = async (v, i) => {
    setIsOnEdit(true);
    let patientData = { ...collectInfo };
    patientData["online_transfer"] = v.online_transfer;
    patientData["cash"] = v.cash;
    patientData["advance"] = v.advance;
    patientData["credit_card"] = v.credit_card;
    patientData["cheque"] = v.cheque;
    patientData["debit_card"] = v.debit_card;
    patientData["payer_Name"] = v.payer_Name;
    patientData["applicable"] = v.applicable;
    patientData["totalPayAmount"] = v.totalPayAmount;
    patientData["instrument_Number"] = v.instrument_Number;
    patientData["Bank_And_Cash_Account"] = v.Bank_And_Cash_Account;
    patientData["Comments"] = v.Comments;
    patientData["date"] = v.date;
    patientData.id = v.id;
    await setCollectInfo({ ...patientData });
  };
  function calculateTotalCollection(data) {
    const totalPayment = data.reduce(
      (total, item) => total + Number(item.totalPayAmount),
      0
    );
    return totalPayment;
  }
  function calculateTotalBalance(data) {
    let totalBalanceAmount = 0;
    const totalPayment = data.reduce(
      (total, item) => total + Number(item.totalPayAmount),
      0
    );
    totalBalanceAmount = (props.totCollectAmt - totalPayment).toFixed(2);
    return totalBalanceAmount;
}
  
  let totalCollectionAmount = calculateTotalCollection(props.collectionsData);
  let totalBalanceAmount = calculateTotalBalance(props.collectionsData);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={7}>
        <Div id={`${parent_id}referral_parent_parent_div`} className="pi-root">
          <Paper
            id={`${parent_id}referral_parent_parent_paper`}
            className="pi-paper"
          >
            <Div
              id={`${parent_id}referral_parent_label_div`}
              style={{ textAlign: "center" }}
            >
              <H6
                id={`${parent_id}referral_parent_label_h6`}
                className="pi-title"
                style={{ fontWeight: 300, fontSize: "24px" }}
              >
                {props?.isAdvancePayment
                  ? "Advance Cash Collection Summary Report": props?.isRefund ? "Refund Cash Summary Report"
                  : "Cash Collection Summary Report"}
              </H6>
            </Div>
            <Row id={`${parent_id}referral_parent_parent_row`}>
              <Col
                id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                md={12}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <Typography variant="h7">Payment Amount ({decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode})</Typography>
              </Col>
              <Col
                id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                md={12}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <TextInput
                  className={"textInput"}
                  id={"discAmtTab"}
                  label={""}
                  placeholder=""
                  type="number"
                  labelStyle={styles.labelStyle}
                  style={{
                    ...styles.borderStyle,
                    ...styles.inputStyle,
                    width: "100%",
                    height: "30px",
                  }}
                  disabled={props?.isPaymentEqual}
                  value={collectInfo?.totalPayAmount}
                  onChange={(e) => setData("totalPayAmount", e.target.value)}
                  hoverColor={UIColor.lineBorderFill.color}
                />
              </Col>
            </Row>
            <Row>
              <Col
                id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                md={12}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <Typography variant="h7">Instrument Type</Typography>
              </Col>
              <Col
                id={`${parent_id}referral_parent_Referring_from_Facility_col`}
                md={12}
                lg={10} // Increase the size to accommodate checkboxes
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {filteredOptions.map((option) => (
                    <React.Fragment key={option._id}>
                      <Checkbox
                        id={`${parent_id}referral_parent_Referring_from_Facility_${option.value
                          .toLowerCase()
                          .replace(/\s+/g, "_")}`}
                        name=""
                        checked={
                          collectInfo?.[
                            option.value.toLowerCase().replace(/\s+/g, "_")
                          ]
                        }
                        onChange={(e) =>
                          setData(
                            option.value.toLowerCase().replace(/\s+/g, "_"),
                            !collectInfo?.[
                              option.value.toLowerCase().replace(/\s+/g, "_")
                            ]
                          )
                        }
                        />
                      <Typography
                        variant="body1"
                        component="span"
                        style={{ marginRight: "10px" }}
                      >
                        {option.label}
                      </Typography>
                    </React.Fragment>
                  ))}
                </div>
              </Col>
            </Row>

            {collectInfo?.cash || collectInfo?.advance ? null : (
              <>
                <Row id={`${parent_id}referral_parent_parent_row`}>
                  <Col
                    id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                    md={12}
                    lg={2}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                    inLineStyles={{ padding: 10 }}
                  >
                    <Typography variant="h7">Instrument Number</Typography>
                  </Col>
                  <Col
                    id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                    md={12}
                    lg={6}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                    inLineStyles={{ padding: 10 }}
                  >
                    <TextInput
                      className={"textInput"}
                      id={"discAmtTab"}
                      label={""}
                      placeholder=""
                      type="number"
                      labelStyle={styles.labelStyle}
                      style={{
                        ...styles.borderStyle,
                        ...styles.inputStyle,
                        width: "100%",
                        height: "30px",
                      }}
                      value={collectInfo?.instrument_Number}
                      onChange={(e) =>
                        setData("instrument_Number", e.target.value)
                      }
                      disabled={props?.isPaymentEqual}
                      hoverColor={UIColor.lineBorderFill.color}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col
                    id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                    md={12}
                    lg={2}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                    inLineStyles={{ padding: 10 }}
                  >
                    <Typography variant="h7">Bank Name</Typography>
                  </Col>
                  <Col
                    id={`${parent_id}referral_parent_Referring_from_Facility_col`}
                    md={12}
                    lg={6}
                    sm={12}
                    xs={12}
                    className="no-padding-left"
                    inLineStyles={{ padding: 10 }}
                  >
                    <TextInput
                      id={`${parent_id}referral_parent_Date_of_Referral_In_label_textinput`}
                      onChange={(e) =>
                        setData("Bank_And_Cash_Account", e.target.value)
                      }
                      value={collectInfo?.Bank_And_Cash_Account}
                      placeholder=""
                      labelStyle={labelStyle}
                      style={{
                        ...styles.borderStyle,
                        ...styles.inputStyle,
                        width: "100%",
                        height: "30px",
                      }}
                      disabled={props?.isPaymentEqual}
                      //   hoverColor="#0071F2"
                      type="text"
                    />
                  </Col>
                </Row>
              </>
            )}

            <Row id={`${parent_id}referral_parent_parent_row`}>
              <Col
                id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                md={12}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <Typography variant="h7">Payer Name</Typography>
              </Col>
              <Col
                id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                md={12}
                lg={6}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <TextInput
                  className={"textInput"}
                  id={"discAmtTab"}
                  placeholder=""
                  type="text"
                  labelStyle={styles.labelStyle}
                  style={{
                    ...styles.borderStyle,
                    ...styles.inputStyle,
                    width: "100%",
                    height: "30px",
                  }}
                  value={collectInfo?.payer_Name}
                  onChange={(e) => setData("payer_Name", e.target.value)}
                  disabled={props?.isPaymentEqual}
                  hoverColor={UIColor.lineBorderFill.color}
                />
              </Col>
            </Row>
            <Row id={`${parent_id}referral_parent_parent_row`}>
              <Col
                id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                md={12}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <Typography variant="h7">Comments</Typography>
              </Col>
              <Col
                id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                md={12}
                lg={6}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <TextareaAutosize
                  aria-label="empty textarea"
                  style={{
                    ...styles.borderStyle,
                    ...styles.inputStyle,
                    width: "100%",
                    height: "30px",
                  }}
                  value={collectInfo?.Comments}
                  onChange={(e) => setData("Comments", e.target.value)}
                  hoverColor={UIColor.lineBorderFill.color}
                  disabled={props?.isPaymentEqual}
                />
              </Col>
            </Row>
            <Row>
              <Col
                id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                md={12}
                lg={2}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <Typography variant="h7">Collection Date</Typography>
              </Col>
              <Col
                id={`${parent_id}referral_parent_Date_of_Referral_In_col`}
                md={12}
                lg={6}
                sm={12}
                xs={12}
                className="no-padding-left"
                inLineStyles={{ padding: 10 }}
              >
                <TextInput
                  id={`${parent_id}referral_parent_Date_of_Referral_In_label_textinput`}
                  onChange={(e) => setData("date", e.target.value)}
                  value={collectInfo?.date ?? ""}
                  //   label="Date"
                  //   placeholder=""
                  labelStyle={labelStyle}
                  style={{
                    ...styles.borderStyle,
                    ...styles.inputStyle,
                    width: "100%",
                    height: "30px",
                  }}
                  hoverColor="#0071F2"
                  type="date"
                  disabled={props?.isPaymentEqual}
                  isRequired={true}
                />
              </Col>
            </Row>
            {/* <Button
              id={`${parent_id}_Financial_save_buttom`}
              inLineStyles={{
                ...containedButton,
                // marginTop: 30,
                // marginBottom: 20,
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              disabled={props?.isSubmit}
              onClick={() => props?.successfun()}
            >
              {props?.isSubmit ? "Submit..." : "Submit"}
            </Button> */}
            <Button
              id={`${parent_id}_Financial_save_buttom`}
              inLineStyles={{
                ...containedButton,
                // marginTop: 30,
                // marginBottom: 20,
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              disabled={props?.isSubmit}
              // onClick={() => props?.successfun()}
              onClick={() => handlefinalsubmit()}
            >
              {isOnEdit ? "Update" : "Add"}
            </Button>
          </Paper>
        </Div>
      </Grid>
      <Grid item xs={12} sm={5}>
        {(
          <Div
            className="pv-root"
            style={{
              margin: "3px 15px",
              minHeight: "410px",
            }}
          >
            <Paper
              id={`${parent_id}referral_parent_parent_paper`}
              className="pi-paper"
              style={{ minHeight: "410px" }}
            >
              <Div
                id={`${parent_id}referral_parent_label_div`}
                style={{ textAlign: "center" }}
              >
                <H6
                  id={`${parent_id}referral_parent_label_h6`}
                  className="pi-title"
                  style={{ fontWeight: 300, fontSize: "24px" }}
                >
                 {props?.isAdvancePayment
                  ? "Advance Cash Collection Summary Report": props?.isRefund ? "Refund Cash Summary Report"
                  : "Cash Collection Summary Report"}
                </H6>
                {props.collectionsData?.map((v, i) => {
                  return (
                    <Div
                      id="financial_applicable_div"
                      style={{
                        marginBottom: 16,
                        marginTop: 16,
                        // minHeight: "250px",
                      }}
                    >
                      <Div style={{ marginBottom: "10px" }}>
                        <PaymentDetailCard
                          parent_id={"financial_showlist"}
                          onedit={() => onEdit(v, i)}
                          ondelete={() => onDelete(v, i)}
                          data={v}
                          index={i + 1}
                        />
                      </Div>
                    </Div>
                  );
                })}
                <Grid
                  container
                  justifyContent="space-between"
                  style={{ margin: "10px" }}
                >
                  <Grid
                    item
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <H6>Paid Amount :</H6>
                    <Text
                      style={{
                        color: "#6F6F6F",
                        fontSize: 20,
                        marginLeft: 10,
                      }}
                    >
                        {decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode} {totalCollectionAmount == "" ? "0" : totalCollectionAmount}
                    </Text>
                  </Grid>
                  <Grid
                    item
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <H6>Balance :</H6>
                    <Text
                      style={{
                        color: "#6F6F6F",
                        fontSize: 20,
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                    >
                      {decodedUserinfo?.currencyinfo?.currencysymbol || decodedUserinfo?.currencyinfo?.currencycode} {totalBalanceAmount == "" ? "0" : totalBalanceAmount}
                    </Text>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ margin: "10px 0px 10px 0px" }}
                >
                  <Button
                    id={`${parent_id}_Financial_save_buttom`}
                    inLineStyles={{
                      ...containedButton,
                      // marginTop: 30,
                      // marginBottom: 20,
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    disabled={props?.isSubmit || !(totalBalanceAmount == 0)}
                    onClick={() => props?.successfun()}
                    // onClick={() => handlesubmit()}
                  >
                    {props?.isSubmit ? "Submit..." : "Submit"}
                  </Button>
                </Grid>
              </Div>
            </Paper>

            {/* </Paper> */}
          </Div>
        )}
      </Grid>
    </Grid>
  );
};

export default withAllContexts(CollectionSummary);
